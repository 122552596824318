import React,{useContext, useEffect, useState} from "react";
import "./profile.css";
import profileIcon from "../../assets/profile/9060728.jpg";
import {
  CoinIcon,
  LeftIcon,
  RightIcon,
  YoutubeRed,
} from "../../common-componants/icons/icons";
import Slider from "react-slick";
import SliderImg from "../../assets/rabbitGame-removebg-preview.png";
import UserContext from "../../context/userContext";
import { userInstance } from "../../config/axios";
import profileImg from "../../assets/profile.jpeg" 

function Profile() {
  const {user} = useContext(UserContext)
  const[updatedUser, setUpdatedUser] = useState(user)
  const[skins, setSkins] = useState()

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "none" }}
        onClick={onClick}
      >
        <LeftIcon />
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "none" }}
        onClick={onClick}
      >
        <RightIcon />
      </div>
    );
  }

  const getSkins = async() => {
    const resp = await userInstance().get("/getSkins")
    setSkins(resp.data)
  }

  useEffect(()=>{
    getSkins()
  },[])

  const buy = async(name)=>{
    const resp =await userInstance().post("/buySkin",{
      userId: user.userId,
      name:name
    })
    setUpdatedUser(resp.data)
  }

  const choose = async(name)=>{
    const resp =await userInstance().post("/chooseSkin",{
      userId: user.userId,
      name:name
    })
    setUpdatedUser(resp.data)
  }
  
  // const sliderRepeat = [1, 2, 3, 4, 5,6,7,8];
  return (
    <div className="profileMainWrap px-3">
      <h1 className="text-center">Profile</h1>
      <div className="userNBameWrap borderBottom d-flex align-items-center mt-2">
        <span>
          <img src={user?.profileImage || profileImg} alt="profile" />
        </span>
        <p>{user?.name}</p>
      </div>
      <div className="levelSliderWrap profileSliderMainWrap">
        {/* <Slider {...settings}> */}
              <div
                className="sliderWrap profileSliderWrap text-center m-auto"
              >
                <div className="profileSliderHeading">
                  <h2>Profile picture</h2>
                </div>
                <img src={profileImg} alt="" />
              </div>
        {/* </Slider> */}
      </div>
    </div>
    // ----------Remove comment and remove above div when you want to use skins....-------------------------
    // <div className="profileMainWrap px-3">
    //   <h1 className="text-center">Profile</h1>
    //   <div className="userNBameWrap borderBottom d-flex align-items-center mt-2">
    //     <span>
    //       <img src={updatedUser.selectedSkin} alt="profile" />
    //     </span>
    //     <p>{user?.name}</p>
    //   </div>
    //   <div className="levelSliderWrap profileSliderMainWrap">
    //     <Slider {...settings}>
    //       {skins?.map((data) => {
    //         const ownedSkin = updatedUser.skin.find(skin => skin.name === data.name);
    //         const isSelectedSkin = updatedUser.selectedSkin === data.image;
    //         return (
    //           <div
    //             className="sliderWrap profileSliderWrap text-center m-auto"
    //           >
    //             <div className="profileSliderHeading">
    //               <h2>Skin</h2>
    //             </div>
    //             <img src={data.image} alt="" />
    //             <div className="profileSliderBottomDiv mt-3">
    //               <div className="profileSliderHeading">
    //                 <h2>{data.name}</h2>
    //                 <p>{data.description}</p>
    //                 <div>
    //                   <CoinIcon />
    //                   <p>{data.price}</p>
    //                 </div>
    //                 <div className="btnsDivWrap airDropsBtnWrap mt-3">
    //                 {ownedSkin ? (
    //                   <div>
    //                     {isSelectedSkin ? (
    //                       <button>In Use</button>
    //                     ) : (
    //                       <button onClick={() => choose(data.name)}>Choose</button>
    //                     )}
    //                   </div>
    //                 ) : (
    //                   <div>
    //                     {user.tokens < data.price ? (
    //                       <button>Not enough balance</button>
    //                     ) : (
    //                       <button onClick={() => buy(data.name)}>Buy</button>
    //                     )}
    //                   </div>
    //                 )}    
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         );
    //       })}
    //     </Slider>
    //   </div>
    // </div>
  );
}

export default Profile;
