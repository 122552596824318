import React from "react";

export const ExchangeIcon = () => {
  return (
    <svg
      fill="#fff"
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      id="exchange-dollar"
      data-name="Flat Line"
      xmlns="http://www.w3.org/2000/svg"
      className="icon flat-line"
    >
      <path
        id="primary"
        d="M20,12a7.91,7.91,0,0,0-.35-2.35,8,8,0,0,0-14.35-2"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
        }}
      ></path>
      <path
        id="primary-2"
        data-name="primary"
        d="M4,12a7.91,7.91,0,0,0,.35,2.35,8,8,0,0,0,14.35,2"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
        }}
      ></path>
      <polyline
        id="primary-3"
        data-name="primary"
        points="19 11 20 12 21 11"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
        }}
      ></polyline>
      <polyline
        id="primary-4"
        data-name="primary"
        points="5 13 4 12 3 13"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
        }}
      ></polyline>
      <path
        id="primary-5"
        data-name="primary"
        d="M10,15h2.5A1.5,1.5,0,0,0,14,13.5h0A1.5,1.5,0,0,0,12.5,12h-1A1.5,1.5,0,0,1,10,10.5h0A1.5,1.5,0,0,1,11.5,9H14"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
        }}
      ></path>
      <path
        id="primary-6"
        data-name="primary"
        d="M12,9V8m0,8V15"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
        }}
      ></path>
    </svg>
  );
};

export const SVGComponent = (props) => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 0.9 0.9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.713 0.381a0.021 0.021 0 0 0 -0.005 0.018Q0.713 0.424 0.713 0.45a0.263 0.263 0 0 1 -0.456 0.177 0.02 0.02 0 0 0 -0.028 -0.001 0.018 0.018 0 0 0 -0.001 0.025 0.3 0.3 0 0 0 0.517 -0.261c-0.003 -0.014 -0.02 -0.019 -0.031 -0.008"
      fill="#000000"
    />
    <path
      d="M0.667 0.269a0.018 0.018 0 0 0 0.001 -0.025A0.3 0.3 0 0 0 0.157 0.516c0.003 0.014 0.02 0.018 0.031 0.008 0.005 -0.005 0.007 -0.012 0.005 -0.019A0.263 0.263 0 0 1 0.639 0.268a0.02 0.02 0 0 0 0.028 0.001"
      fill="#000000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.68 0.188a0.019 0.019 0 0 1 0.019 0.019v0.075a0.019 0.019 0 0 1 -0.019 0.019h-0.075a0.019 0.019 0 1 1 0 -0.037h0.056V0.206a0.019 0.019 0 0 1 0.019 -0.019"
      fill="#000000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.207 0.606A0.019 0.019 0 0 1 0.22 0.6h0.075a0.019 0.019 0 0 1 0 0.037h-0.056v0.056a0.019 0.019 0 1 1 -0.037 0v-0.075a0.019 0.019 0 0 1 0.005 -0.013"
      fill="#000000"
    />
    <path
      d="M0.458 0.463v0.073c0.025 -0.003 0.043 -0.017 0.043 -0.038 0 -0.021 -0.02 -0.029 -0.042 -0.035z"
      fill="#000000"
    />
    <path
      d="M0.405 0.4c0 0.021 0.021 0.029 0.037 0.034V0.365c-0.023 0.003 -0.037 0.017 -0.037 0.034"
      fill="#000000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.45 0.637a0.188 0.188 0 1 0 0 -0.375 0.188 0.188 0 0 0 0 0.375m0.008 -0.056h-0.017v-0.023c-0.04 -0.003 -0.065 -0.025 -0.067 -0.058h0.026c0.002 0.021 0.019 0.032 0.041 0.034v-0.078l-0.008 -0.002c-0.033 -0.009 -0.053 -0.026 -0.053 -0.055 0 -0.032 0.026 -0.055 0.061 -0.058V0.319h0.017v0.024c0.037 0.002 0.063 0.025 0.064 0.055h-0.025c-0.002 -0.019 -0.018 -0.03 -0.04 -0.033v0.073l0.008 0.002c0.022 0.006 0.059 0.018 0.059 0.058 0 0.032 -0.024 0.057 -0.067 0.06z"
      fill="#000000"
    />
  </svg>
);

export const AxeIcon = (props) => (
  <svg
    fill="#000000"
    width="30px"
    height="30px"
    viewBox="0 0 0.825 0.825"
    xmlns="http://www.w3.org/2000/svg"
    id="memory-axe"
    {...props}
  >
    <path d="M0.412 0.112h0.075v0.037h0.037v0.037h0.037v0.037h0.075v0.037h0.075v0.037h0.037v0.037h0.037v0.075h-0.037v0.075h-0.037v0.037h-0.037v0.037h-0.075v0.037h-0.037v-0.037h-0.037v-0.037h-0.037v-0.075h-0.037v-0.037h-0.037v-0.037h-0.037V0.337H0.337V0.3H0.3V0.225h0.037V0.188h0.037V0.15h0.037m-0.037 0.225v0.037h0.037v0.075h-0.037v0.037H0.337v0.037H0.3v0.037H0.263v0.037H0.225v0.037H0.188v0.037H0.15v0.037H0.112v-0.037H0.075v-0.075h0.037v-0.037h0.037v-0.037h0.037v-0.037h0.037v-0.037h0.037v-0.037h0.037v-0.037h0.037v-0.037" />
  </svg>
);

export const FriendsIcon = (props) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    style={{
      enableBackground: "new 0 0 35.695 35.695",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M9.714 9.049a5.286 5.286 0 1 1 10.571 0 5.284 5.284 0 0 1 -5.286 5.286 5.286 5.286 0 0 1 -5.286 -5.286m20.263 9.951 -0.739 -4.429c-0.133 -0.802 -0.808 -1.474 -1.594 -1.667a3.95 3.95 0 0 1 -2.636 1.001c-1.011 0 -1.933 -0.38 -2.635 -1.001 -0.788 0.192 -1.462 0.866 -1.594 1.667l-0.018 0.104a3.488 3.488 0 0 0 -1.57 -0.884 6.303 6.303 0 0 1 -8.383 0 3.488 3.488 0 0 0 -1.57 0.884l-0.018 -0.104c-0.133 -0.802 -0.809 -1.474 -1.594 -1.667a3.966 3.966 0 0 1 -5.27 0c-0.787 0.192 -1.461 0.866 -1.594 1.667l-0.739 4.429c-0.159 0.951 0.501 1.729 1.463 1.729h6.07l-0.461 2.759c-0.252 1.512 0.797 2.749 2.329 2.749H20.574c1.532 0 2.581 -1.24 2.329 -2.749l-0.461 -2.759h6.07c0.963 -0.002 1.622 -0.778 1.464 -1.729m-4.969 -5.753a3.323 3.323 0 1 0 0 -6.645 3.323 3.323 0 0 0 0 6.645m-20.017 0a3.322 3.322 0 1 0 0 -6.645 3.322 3.322 0 0 0 0 6.645" />
    </g>
  </svg>
);

export const CoinSvg = (props) => (
  <svg
    fill="#000000"
    width="30px"
    height="30px"
    viewBox="0 0 0.9 0.9"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0.787 0.546C0.787 0.617 0.695 0.675 0.581 0.675S0.375 0.617 0.375 0.546s0.092 -0.129 0.206 -0.129 0.206 0.058 0.206 0.129m-0.206 0.198c-0.114 0 -0.206 -0.058 -0.206 -0.129v0.08C0.375 0.767 0.467 0.825 0.581 0.825s0.206 -0.058 0.206 -0.129v-0.08c0 0.071 -0.092 0.129 -0.206 0.129m-0.263 -0.412c0.114 0 0.206 -0.058 0.206 -0.129S0.433 0.075 0.319 0.075 0.112 0.133 0.112 0.204s0.092 0.129 0.206 0.129m-0.005 0.15a0.21 0.21 0 0 1 0.082 -0.09 0.315 0.315 0 0 1 -0.077 0.009C0.205 0.402 0.112 0.344 0.112 0.273v0.08c0 0.07 0.09 0.127 0.202 0.128ZM0.319 0.825a0.337 0.337 0 0 0 0.04 -0.003 0.184 0.184 0 0 1 -0.051 -0.078C0.199 0.741 0.112 0.685 0.112 0.616v0.08C0.112 0.767 0.205 0.825 0.319 0.825M0.3 0.658V0.578c-0.105 -0.006 -0.188 -0.06 -0.188 -0.128v0.08c0 0.067 0.083 0.122 0.188 0.128" />
  </svg>
);

export const ExportIcon = (props) => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 0.9 0.9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.53 0.425a0.083 0.083 0 0 0 -0.101 -0.101c-0.029 0.007 -0.052 0.031 -0.06 0.06a0.083 0.083 0 0 0 0.101 0.101c0.029 -0.007 0.053 -0.031 0.06 -0.06"
      fill="#292D32"
    />
    <path
      d="M0.226 0.674c-0.007 0 -0.014 -0.003 -0.019 -0.007a0.358 0.358 0 0 1 -0.114 -0.262c0 -0.197 0.161 -0.358 0.358 -0.358s0.358 0.161 0.358 0.358c0 0.1 -0.04 0.192 -0.113 0.261 -0.011 0.011 -0.029 0.01 -0.04 -0.001s-0.01 -0.029 0.001 -0.04a0.298 0.298 0 0 0 0.095 -0.219c0 -0.166 -0.135 -0.301 -0.301 -0.301S0.148 0.239 0.148 0.405c0 0.085 0.034 0.163 0.096 0.221 0.011 0.011 0.012 0.028 0.002 0.04a0.029 0.029 0 0 1 -0.021 0.009"
      fill="#292D32"
    />
    <path
      d="M0.6 0.574a0.028 0.028 0 0 1 -0.02 -0.047c0.031 -0.033 0.048 -0.076 0.048 -0.121 0 -0.098 -0.08 -0.178 -0.178 -0.178a0.178 0.178 0 0 0 -0.178 0.178c0 0.045 0.017 0.088 0.048 0.121 0.011 0.011 0.01 0.029 -0.001 0.04 -0.011 0.011 -0.029 0.01 -0.04 -0.001a0.234 0.234 0 0 1 -0.064 -0.16c0 -0.129 0.105 -0.234 0.234 -0.234s0.234 0.105 0.234 0.234c0 0.06 -0.022 0.117 -0.064 0.16a0.029 0.029 0 0 1 -0.021 0.009"
      fill="#292D32"
    />
    <path
      d="m0.386 0.625 -0.054 0.067C0.289 0.745 0.328 0.825 0.396 0.825h0.108c0.069 0 0.107 -0.08 0.064 -0.133l-0.054 -0.067a0.081 0.081 0 0 0 -0.127 0"
      fill="#292D32"
    />
  </svg>
);

export const InfoIcon = (props) => (
  <svg
    fill="#000000"
    width="30px"
    height="30px"
    viewBox="0 0 0.9 0.9"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    {...props}
  >
    <path d="M0.45 0.375a0.037 0.037 0 0 0 -0.037 0.037v0.225a0.037 0.037 0 0 0 0.075 0v-0.225a0.037 0.037 0 0 0 -0.037 -0.037m0 -0.15a0.047 0.047 0 1 0 0.047 0.047A0.047 0.047 0 0 0 0.45 0.225" />
  </svg>
);

export const LeaderBoard = (props) => (
  <svg
    id="Layer_1"
    enable-background="new 0 0 64 64"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m25.9813843 34.4975128v16.3103638h12.0372314v-12.114502-5.887268-4.074646h-12.0372314z" />
      <path d="m40.215 39.792h12.037v11.016h-12.037z" />
      <path d="m50.4790649 33.9043732h-10.2638549v3.6906738h11.578247z" />
      <path d="m56.3314209 46.9842072-1.8823242-.5214234v4.3450928h3.4590454z" />
      <path d="m23.78479 29.70961h-10.3115844l-1.2800904 3.6896362h11.5916748z" />
      <path d="m9.5509033 46.4627838-1.8823242.5214234-1.5767212 3.8236694h3.4590454z" />
      <path d="m53.3508301 53.0044708h-14.2338867-14.2338868-14.2338867-5.0999756v2.8829956h52.9016114v-2.8829956z" />
      <path d="m27.1177368 22.8452911-.8532104 3.6895752h11.4709472l-.8532104-3.6895752z" />
      <path d="m11.747 35.596h12.037v15.212h-12.037z" />
      <path d="m27.9087524 16.3017731c.1617432.1577148.2355957.3848877.1973877.6074829l-.6087036 3.5492554c-.0961914.5606689.4923096.9882202.9957886.7235107l3.1873779-1.6757202c.1999512-.1051025.4388428-.1051025.6387939 0l3.1873779 1.6757202c.503479.2647095 1.09198-.1628418.9957886-.7235107l-.6087036-3.5492554c-.038208-.2225952.0356445-.4497681.1973877-.6074829l2.5786743-2.5135498c.4072876-.3970947.1825562-1.0888672-.3803711-1.1706543l-3.5636597-.5178223c-.2235107-.0324707-.416748-.1728516-.5167236-.3754272l-1.59375-3.229187c-.251709-.5101318-.979126-.5101318-1.230835 0l-1.59375 3.229187c-.0999756.2025757-.2932129.3429565-.5167236.3754272l-3.5636597.5178223c-.5629272.0817871-.7876587.7735596-.3803711 1.1706543z" />
    </g>
  </svg>
);

export const CoinIcon = (props) => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 4.8 4.8"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    className="iconify iconify--noto"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <path
      cx={64}
      cy={66.58}
      r={57.36}
      fill="#d68f30"
      d="M4.551 2.497A2.151 2.151 0 0 1 2.4 4.648A2.151 2.151 0 0 1 0.249 2.497A2.151 2.151 0 0 1 4.551 2.497z"
    />
    <path
      d="M0.395 3.055v0.22c0.022 0.058 0.048 0.115 0.075 0.171V3.055z"
      fill="#bc6f00"
    />
    <path
      d="M0.64 3.469v0.264c0.03 0.042 0.061 0.084 0.094 0.124V3.469z"
      fill="#bc6f00"
    />
    <path
      d="M0.965 3.814v0.285c0.036 0.033 0.074 0.064 0.112 0.094v-0.378z"
      fill="#bc6f00"
    />
    <path
      d="M1.365 4.076v0.306c0.037 0.02 0.074 0.039 0.112 0.058v-0.364z"
      fill="#bc6f00"
    />
    <path
      d="M1.818 4.243v0.324c0.037 0.011 0.075 0.02 0.112 0.028v-0.352z"
      fill="#bc6f00"
    />
    <path
      d="M2.349 4.301v0.345c0.017 0 0.034 0.001 0.051 0.001 0.021 0 0.041 -0.001 0.061 -0.002v-0.345z"
      fill="#bc6f00"
    />
    <path
      d="M2.88 4.24v0.352c0.038 -0.009 0.075 -0.018 0.112 -0.029v-0.324z"
      fill="#bc6f00"
    />
    <path
      d="M3.332 4.071v0.364c0.038 -0.018 0.076 -0.038 0.112 -0.059v-0.305z"
      fill="#bc6f00"
    />
    <path
      d="M3.731 3.807v0.379a2.1 2.1 0 0 0 0.112 -0.095v-0.284z"
      fill="#bc6f00"
    />
    <path
      d="M4.166 3.461h-0.094v0.388c0.033 -0.041 0.064 -0.082 0.094 -0.125z"
      fill="#bc6f00"
    />
    <path
      d="M4.41 3.046h-0.075v0.391c0.027 -0.057 0.052 -0.115 0.075 -0.174z"
      fill="#bc6f00"
    />
    <path
      cx={64}
      cy={61.42}
      r={57.36}
      fill="#fff176"
      d="M4.551 2.303A2.151 2.151 0 0 1 2.4 4.454A2.151 2.151 0 0 1 0.249 2.303A2.151 2.151 0 0 1 4.551 2.303z"
    />
    <path
      cx={64}
      cy={61.42}
      r={52.25}
      fill="#f2bc1a"
      d="M4.359 2.303A1.959 1.959 0 0 1 2.4 4.263A1.959 1.959 0 0 1 0.441 2.303A1.959 1.959 0 0 1 4.359 2.303z"
    />
    <path
      d="M0.437 2.378c-0.014 -0.258 0.031 -0.52 0.121 -0.765 0.094 -0.244 0.237 -0.471 0.418 -0.663C1.34 0.566 1.868 0.343 2.4 0.34c0.532 0.003 1.06 0.226 1.423 0.611 0.181 0.192 0.325 0.418 0.418 0.662 0.09 0.246 0.135 0.507 0.121 0.765h-0.007a1.98 1.98 0 0 0 -0.15 -0.75 1.98 1.98 0 0 0 -0.423 -0.636 1.961 1.961 0 0 0 -0.634 -0.427 1.942 1.942 0 0 0 -1.497 0 1.961 1.961 0 0 0 -0.634 0.427 1.976 1.976 0 0 0 -0.423 0.636 1.98 1.98 0 0 0 -0.15 0.75z"
      fill="#e08f00"
    />
    <path
      d="M2.4 0.153c-1.188 0 -2.151 0.963 -2.151 2.151S1.212 4.455 2.4 4.455s2.151 -0.963 2.151 -2.151S3.588 0.153 2.4 0.153m0 4.11c-1.082 0 -1.959 -0.877 -1.959 -1.959C0.441 1.221 1.318 0.344 2.4 0.344s1.959 0.877 1.959 1.959S3.482 4.263 2.4 4.263"
      fill="#fff176"
    />
    <path
      fill="#d38200"
      d="m1.425 0.801 0.048 0.147 0.155 -0.059v0.059l-0.126 0.091 0.048 0.088v0.06l-0.125 -0.091 -0.126 0.091v-0.06l0.048 -0.088 -0.125 -0.091V0.889l0.155 0.059z"
    />
    <path
      fill="#d38200"
      d="m0.825 1.647 0.048 0.147 0.155 -0.059v0.059l-0.125 0.091 0.048 0.088v0.059l-0.126 -0.091 -0.125 0.091v-0.059l0.048 -0.088 -0.126 -0.091v-0.059l0.155 0.059z"
    />
    <path
      fill="#d38200"
      d="m0.808 2.689 0.048 0.147 0.155 -0.059v0.059l-0.125 0.091 0.048 0.088v0.059l-0.126 -0.091 -0.125 0.091v-0.059l0.048 -0.088 -0.126 -0.091v-0.059l0.155 0.059z"
    />
    <path
      fill="#d38200"
      d="m1.448 3.517 0.048 0.147 0.155 -0.059v0.059l-0.125 0.091 0.048 0.088v0.06l-0.126 -0.091 -0.125 0.091v-0.06l0.048 -0.088 -0.126 -0.091v-0.059l0.155 0.059z"
    />
    <path
      fill="#d38200"
      d="m3.375 0.801 -0.048 0.147 -0.155 -0.059v0.059l0.126 0.091 -0.048 0.088v0.06l0.125 -0.091 0.126 0.091v-0.06l-0.048 -0.088 0.125 -0.091V0.889l-0.155 0.059z"
    />
    <path
      fill="#d38200"
      d="m3.975 1.647 -0.048 0.147 -0.155 -0.059v0.059l0.125 0.091 -0.048 0.088v0.059l0.126 -0.091 0.125 0.091v-0.059l-0.048 -0.088 0.126 -0.091v-0.059l-0.155 0.059z"
    />
    <path
      fill="#d38200"
      d="m3.992 2.689 -0.048 0.147 -0.155 -0.059v0.059l0.125 0.091 -0.048 0.088v0.059l0.126 -0.091 0.125 0.091v-0.059l-0.048 -0.088 0.126 -0.091v-0.059l-0.155 0.059z"
    />
    <path
      fill="#d38200"
      d="m3.353 3.517 -0.048 0.147 -0.155 -0.059v0.059l0.125 0.091 -0.048 0.088v0.06l0.126 -0.091 0.125 0.091v-0.06l-0.048 -0.088 0.126 -0.091v-0.059l-0.155 0.059z"
    />
    <g>
      <path
        fill="#fff176"
        d="m3.353 3.458 0.048 0.147h0.155l-0.126 0.091 0.048 0.147 -0.125 -0.091 -0.126 0.091 0.048 -0.147 -0.125 -0.091h0.155z"
      />
      <path
        fill="#fff176"
        d="m3.992 2.63 0.048 0.147h0.155l-0.126 0.091 0.048 0.147 -0.125 -0.091 -0.126 0.091 0.048 -0.147 -0.125 -0.091h0.155z"
      />
      <path
        fill="#fff176"
        d="m3.975 1.587 0.048 0.147h0.155l-0.126 0.091 0.048 0.147 -0.125 -0.091 -0.126 0.091 0.048 -0.147 -0.125 -0.091h0.155z"
      />
      <path
        fill="#fff176"
        d="m3.375 0.741 0.048 0.147h0.155l-0.125 0.091 0.048 0.147 -0.126 -0.091 -0.125 0.091 0.048 -0.147 -0.126 -0.091h0.155z"
      />
    </g>
    <g>
      <path
        fill="#d38200"
        d="m2.402 3.844 0.048 0.147 0.155 -0.059v0.059l-0.125 0.091 0.048 0.088v0.059l-0.126 -0.091 -0.125 0.091v-0.059l0.048 -0.088 -0.126 -0.091v-0.059l0.155 0.059z"
      />
      <path
        fill="#fff176"
        d="m2.402 3.765 0.048 0.147h0.155l-0.125 0.091 0.048 0.147 -0.126 -0.091 -0.125 0.091 0.048 -0.147 -0.126 -0.091h0.155z"
      />
      <path
        fill="#d38200"
        d="m2.402 0.483 0.048 0.147 0.155 -0.059v0.059l-0.125 0.091 0.048 0.088v0.059l-0.126 -0.091 -0.125 0.091V0.81l0.048 -0.088 -0.126 -0.091v-0.059l0.155 0.059z"
      />
      <path
        fill="#fff176"
        d="m2.402 0.424 0.048 0.147h0.155l-0.125 0.091 0.048 0.147 -0.126 -0.091 -0.125 0.091 0.048 -0.147 -0.126 -0.091h0.155z"
      />
    </g>
    <g>
      <path
        fill="#fff176"
        d="m1.448 3.458 -0.048 0.147h-0.155l0.126 0.091 -0.048 0.147 0.125 -0.091 0.126 0.091 -0.048 -0.147 0.125 -0.091h-0.155z"
      />
      <path
        fill="#fff176"
        d="m0.808 2.63 -0.048 0.147h-0.155l0.126 0.091 -0.048 0.147L0.808 2.925l0.126 0.091 -0.048 -0.147 0.125 -0.091h-0.155z"
      />
      <path
        fill="#fff176"
        d="m0.825 1.587 -0.048 0.147H0.623l0.126 0.091 -0.048 0.147 0.125 -0.091 0.126 0.091 -0.048 -0.147 0.125 -0.091h-0.155z"
      />
      <path
        fill="#fff176"
        d="m1.425 0.741 -0.048 0.147h-0.155l0.125 0.091 -0.048 0.147 0.126 -0.091 0.125 0.091 -0.048 -0.147 0.126 -0.091h-0.155z"
      />
    </g>
    <path
      d="M3.571 1.808c0 -0.013 -2.339 0 -2.339 0l-0.021 0.063v0.108c0 0.019 0.016 0.035 0.035 0.035h2.308c0.019 0 0.035 -0.016 0.035 -0.035v-0.105z"
      fill="#d38200"
    />
    <path
      d="M3.574 3.206H1.241l-0.156 0.078 0.002 0.101c0 0.011 0.009 0.02 0.021 0.02h2.585c0.011 0 0.02 -0.009 0.021 -0.02l0.002 -0.099z"
      fill="#d38200"
    />
    <g>
      <g fill="#d38200">
        <path d="M1.617 2.899h-0.134c-0.022 0 -0.04 -0.07 -0.04 -0.092l0.214 -0.007c0 0.022 -0.018 0.099 -0.04 0.099" />
        <path d="M1.663 2.175h-0.226c-0.017 0 -0.032 -0.011 -0.036 -0.027l-0.018 -0.147h0.331l-0.015 0.147c-0.004 0.016 -0.019 0.027 -0.036 0.027" />
        <path d="M1.437 2.944h0.226c0.017 0 0.05 0.011 0.055 0.027 0 0 -0.001 0.07 -0.001 0.098s-0.011 0.048 -0.036 0.048h-0.261c-0.025 0 -0.036 -0.022 -0.036 -0.048s-0.001 -0.097 -0.001 -0.097c0.005 -0.016 0.038 -0.027 0.055 -0.027" />
      </g>
    </g>
    <g>
      <g fill="#d38200">
        <path d="M3.361 2.899h-0.134c-0.022 0 -0.04 -0.07 -0.04 -0.092l0.214 -0.007c0 0.022 -0.018 0.099 -0.04 0.099" />
        <path d="M3.407 2.175h-0.226c-0.017 0 -0.032 -0.011 -0.036 -0.027l-0.018 -0.147h0.331l-0.015 0.147c-0.004 0.016 -0.019 0.027 -0.036 0.027" />
        <path d="M3.181 2.944h0.226c0.017 0 0.05 0.011 0.055 0.027 0 0 -0.001 0.07 -0.001 0.098s-0.011 0.048 -0.036 0.048h-0.261c-0.025 0 -0.036 -0.022 -0.036 -0.048s-0.001 -0.097 -0.001 -0.097c0.005 -0.016 0.038 -0.027 0.055 -0.027" />
      </g>
    </g>
    <g>
      <g fill="#d38200">
        <path d="M2.78 2.899h-0.134c-0.022 0 -0.04 -0.07 -0.04 -0.092l0.214 -0.007c0 0.022 -0.018 0.099 -0.04 0.099" />
        <path d="M2.826 2.175h-0.226c-0.017 0 -0.032 -0.011 -0.036 -0.027l-0.018 -0.147h0.331l-0.015 0.147c-0.004 0.016 -0.019 0.027 -0.036 0.027" />
        <path d="M2.599 2.944h0.226c0.017 0 0.05 0.011 0.055 0.027 0 0 -0.001 0.07 -0.001 0.098s-0.011 0.048 -0.036 0.048h-0.261c-0.025 0 -0.036 -0.022 -0.036 -0.048s-0.001 -0.097 -0.001 -0.097c0.005 -0.016 0.038 -0.027 0.055 -0.027" />
      </g>
    </g>
    <g>
      <g fill="#d38200">
        <path d="M2.199 2.899h-0.134c-0.022 0 -0.04 -0.07 -0.04 -0.092l0.214 -0.007c0 0.022 -0.018 0.099 -0.04 0.099" />
        <path d="M2.244 2.175h-0.226c-0.017 0 -0.032 -0.011 -0.036 -0.027l-0.018 -0.147h0.331l-0.015 0.147c-0.004 0.016 -0.019 0.027 -0.036 0.027" />
        <path d="M2.018 2.944h0.226c0.017 0 0.05 0.011 0.055 0.027 0 0 -0.001 0.07 -0.001 0.098s-0.011 0.048 -0.036 0.048h-0.261c-0.025 0 -0.036 -0.022 -0.036 -0.048s-0.001 -0.097 -0.001 -0.097c0.005 -0.016 0.038 -0.027 0.055 -0.027" />
      </g>
    </g>
    <path
      d="M3.588 1.707c0 -0.013 -0.007 -0.026 -0.017 -0.032l-1.169 -0.664 -1.171 0.663c-0.011 0.007 -0.018 0.019 -0.018 0.032l-0.002 0.164c0 0.021 0.017 0.037 0.037 0.037h2.304c0.021 0 0.037 -0.017 0.037 -0.037z"
      fill="#fff176"
    />
    <path fill="#f2bc1a" d="M3.294 1.662 2.4 1.155 1.506 1.662z" />
    <path fill="#fff176" d="M2.965 1.601 2.4 1.279l-0.565 0.321z" />
    <path
      d="M3.502 3.076H1.298c-0.022 0 -0.042 0.009 -0.057 0.026l-0.151 0.169c-0.011 0.012 -0.002 0.03 0.013 0.03h2.594c0.015 0 0.024 -0.018 0.013 -0.03L3.559 3.101c-0.014 -0.016 -0.035 -0.026 -0.057 -0.026"
      fill="#fff176"
    />
    <g fill="#fff176">
      <path d="M3.361 2.832h-0.134c-0.022 0 -0.04 -0.018 -0.04 -0.04V2.192c0 -0.022 0.018 -0.04 0.04 -0.04h0.134c0.022 0 0.04 0.018 0.04 0.04v0.6c0 0.022 -0.018 0.04 -0.04 0.04" />
      <path d="M3.407 2.107h-0.226c-0.017 0 -0.032 -0.011 -0.036 -0.027l-0.017 -0.06c-0.007 -0.024 0.011 -0.048 0.036 -0.048h0.261c0.025 0 0.043 0.024 0.036 0.048l-0.017 0.06c-0.005 0.016 -0.02 0.027 -0.036 0.027" />
      <path d="M3.181 2.877h0.226c0.017 0 0.032 0.011 0.036 0.027l0.017 0.06c0.007 0.024 -0.011 0.048 -0.036 0.048h-0.261c-0.025 0 -0.043 -0.024 -0.036 -0.048l0.017 -0.06c0.005 -0.017 0.02 -0.027 0.036 -0.027" />
      <path d="M1.617 2.832h-0.134c-0.022 0 -0.04 -0.018 -0.04 -0.04V2.192c0 -0.022 0.018 -0.04 0.04 -0.04h0.134c0.022 0 0.04 0.018 0.04 0.04v0.6c0 0.022 -0.018 0.04 -0.04 0.04" />
      <path d="M1.663 2.107h-0.226c-0.017 0 -0.032 -0.011 -0.036 -0.027l-0.017 -0.06c-0.007 -0.024 0.011 -0.048 0.036 -0.048h0.261c0.025 0 0.043 0.024 0.036 0.048l-0.017 0.06c-0.005 0.016 -0.019 0.027 -0.036 0.027" />
      <path d="M1.437 2.877h0.226c0.017 0 0.032 0.011 0.036 0.027l0.017 0.06c0.007 0.024 -0.011 0.048 -0.036 0.048h-0.261c-0.025 0 -0.043 -0.024 -0.036 -0.048l0.017 -0.06c0.005 -0.017 0.019 -0.027 0.036 -0.027" />
      <g>
        <path d="M2.199 2.832h-0.134c-0.022 0 -0.04 -0.018 -0.04 -0.04V2.192c0 -0.022 0.018 -0.04 0.04 -0.04h0.134c0.022 0 0.04 0.018 0.04 0.04v0.6a0.04 0.04 0 0 1 -0.04 0.04" />
        <path d="M2.244 2.107h-0.226c-0.017 0 -0.032 -0.011 -0.036 -0.027l-0.017 -0.06c-0.007 -0.024 0.011 -0.048 0.036 -0.048h0.261c0.025 0 0.043 0.024 0.036 0.048l-0.017 0.06c-0.005 0.016 -0.019 0.027 -0.036 0.027" />
        <path d="M2.018 2.877h0.226c0.017 0 0.032 0.011 0.036 0.027l0.017 0.06c0.007 0.024 -0.011 0.048 -0.036 0.048h-0.261c-0.025 0 -0.043 -0.024 -0.036 -0.048l0.017 -0.06c0.005 -0.017 0.019 -0.027 0.036 -0.027" />
      </g>
      <g>
        <path d="M2.78 2.832h-0.134c-0.022 0 -0.04 -0.018 -0.04 -0.04V2.192c0 -0.022 0.018 -0.04 0.04 -0.04h0.134c0.022 0 0.04 0.018 0.04 0.04v0.6a0.04 0.04 0 0 1 -0.04 0.04" />
        <path d="M2.826 2.107h-0.226c-0.017 0 -0.032 -0.011 -0.036 -0.027l-0.017 -0.06c-0.007 -0.024 0.011 -0.048 0.036 -0.048h0.261c0.025 0 0.043 0.024 0.036 0.048l-0.017 0.06c-0.005 0.016 -0.019 0.027 -0.036 0.027" />
        <path d="M2.599 2.877h0.226c0.017 0 0.032 0.011 0.036 0.027l0.017 0.06c0.007 0.024 -0.011 0.048 -0.036 0.048h-0.261c-0.025 0 -0.043 -0.024 -0.036 -0.048l0.017 -0.06c0.005 -0.017 0.02 -0.027 0.036 -0.027" />
      </g>
    </g>
  </svg>
);

export const QuestionMarkImg = (props) => (
  <svg
    width="100px"
    height="100px"
    viewBox="-0.25 0 1.25 1.25"
    id="meteor-icon-kit__regular-questionmark-s"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.25 0.375a0.125 0.125 0 1 1 -0.25 0c0 -0.194 0.181 -0.375 0.375 -0.375s0.375 0.181 0.375 0.375c0 0.161 -0.063 0.234 -0.194 0.299C0.5 0.702 0.5 0.702 0.5 0.75a0.125 0.125 0 1 1 -0.25 0c0 -0.161 0.063 -0.234 0.194 -0.299C0.5 0.423 0.5 0.423 0.5 0.375c0 -0.056 -0.069 -0.125 -0.125 -0.125s-0.125 0.069 -0.125 0.125m0.125 0.875a0.125 0.125 0 1 1 0 -0.25 0.125 0.125 0 0 1 0 0.25"
      fill="#758CA3"
    />
  </svg>
);

export const ReloadIcon = (props) => (
  <svg
    fill="#fff"
    width="25px"
    height="25px"
    viewBox="0 0 0.9 0.9"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0.15 0.45a0.037 0.037 0 0 1 -0.075 0 0.374 0.374 0 0 1 0.609 -0.292V0.103a0.037 0.037 0 1 1 0.075 0v0.15a0.037 0.037 0 0 1 -0.037 0.037h-0.15a0.037 0.037 0 0 1 0 -0.075h0.065A0.299 0.299 0 0 0 0.15 0.45m0.637 -0.037a0.037 0.037 0 0 0 -0.037 0.037 0.299 0.299 0 0 1 -0.487 0.234h0.065a0.037 0.037 0 1 0 0 -0.075h-0.15a0.037 0.037 0 0 0 -0.037 0.037v0.15a0.037 0.037 0 0 0 0.075 0v-0.054A0.374 0.374 0 0 0 0.825 0.45a0.037 0.037 0 0 0 -0.037 -0.037" />
  </svg>
);

export const UserIcon = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30px"
    height="30px"
    viewBox="0 0 2.25 2.25"
    enableBackground="new 0 0 60 60"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="#fff"
      d="m1.813 1.904 0.01 0.011c-0.187 0.168 -0.435 0.271 -0.707 0.271s-0.519 -0.102 -0.706 -0.27l0.011 -0.012s0.123 -0.098 0.269 -0.134 0.211 -0.138 0.211 -0.138v-0.178s-0.106 -0.142 -0.091 -0.24c0 0 -0.131 -0.087 -0.04 -0.215 0 0 -0.211 -0.605 0.324 -0.611 0.135 -0.001 0.203 0.102 0.203 0.102 0.362 -0.036 0.168 0.51 0.168 0.51 0.091 0.127 -0.04 0.215 -0.04 0.215 0.015 0.098 -0.091 0.24 -0.091 0.24v0.178s0.066 0.102 0.211 0.138c0.145 0.036 0.269 0.134 0.269 0.134"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth={0.11249999999999999}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M1.813 1.904s-0.123 -0.098 -0.269 -0.134 -0.211 -0.138 -0.211 -0.138v-0.178s0.106 -0.142 0.091 -0.24c0 0 0.131 -0.087 0.04 -0.215 0 0 0.194 -0.546 -0.168 -0.51 0 0 -0.067 -0.103 -0.203 -0.102 -0.534 0.006 -0.324 0.611 -0.324 0.611 -0.091 0.127 0.04 0.215 0.04 0.215 -0.015 0.098 0.091 0.24 0.091 0.24v0.178s-0.066 0.102 -0.211 0.138c-0.145 0.036 -0.269 0.134 -0.269 0.134"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeWidth={0.11249999999999999}
      strokeMiterlimit={10}
      d="M0.41 1.917C0.194 1.723 0.057 1.441 0.057 1.128c0 -0.585 0.474 -1.059 1.059 -1.059C1.701 0.069 2.175 0.543 2.175 1.128c0 0.313 -0.136 0.595 -0.352 0.788 -0.187 0.168 -0.435 0.271 -0.707 0.271s-0.519 -0.102 -0.706 -0.27z"
    />
  </svg>
);

export const CopyIcon = (props) => (
  <svg
    fill="#fff"
    width="30px"
    height="30px"
    viewBox="0 0 0.9 0.9"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m0.112 0.675 0.004 0a0.037 0.037 0 0 0 0.033 -0.033L0.15 0.637V0.15h0.487l0.004 0a0.037 0.037 0 0 0 0 -0.074L0.637 0.075H0.112l-0.004 0a0.037 0.037 0 0 0 -0.033 0.033L0.075 0.112v0.525l0 0.004a0.037 0.037 0 0 0 0.033 0.033zm0.675 0.15 0.004 0a0.037 0.037 0 0 0 0.033 -0.033L0.825 0.787V0.263l0 -0.004a0.037 0.037 0 0 0 -0.029 -0.032l-0.004 -0.001L0.787 0.225H0.263l-0.004 0a0.037 0.037 0 0 0 -0.033 0.033L0.225 0.263v0.525l0 0.004a0.037 0.037 0 0 0 0.029 0.032l0.004 0.001L0.263 0.825zM0.3 0.75V0.3h0.45v0.45z" />
  </svg>
);

export const YoutubeRed = (props) => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 0.8 0.8"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      fill="red"
      d="M0.736 0.232a0.088 0.088 0 0 0 -0.062 -0.062C0.619 0.155 0.4 0.155 0.4 0.155s-0.219 0 -0.274 0.014c-0.03 0.008 -0.054 0.032 -0.062 0.062C0.05 0.286 0.05 0.4 0.05 0.4s0 0.114 0.014 0.168c0.008 0.03 0.032 0.054 0.062 0.062C0.181 0.645 0.4 0.645 0.4 0.645s0.219 0 0.274 -0.014a0.088 0.088 0 0 0 0.062 -0.062C0.75 0.514 0.75 0.4 0.75 0.4s0 -0.114 -0.014 -0.168"
    />
    <path fill="#ffffff" d="m0.33 0.505 0.182 -0.105 -0.182 -0.105z" />
  </svg>
);

export const YoutubeRedLarge = (props) => (
  <svg
    width="100px"
    height="100px"
    viewBox="0 0 0.8 0.8"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      fill="red"
      d="M0.736 0.232a0.088 0.088 0 0 0 -0.062 -0.062C0.619 0.155 0.4 0.155 0.4 0.155s-0.219 0 -0.274 0.014c-0.03 0.008 -0.054 0.032 -0.062 0.062C0.05 0.286 0.05 0.4 0.05 0.4s0 0.114 0.014 0.168c0.008 0.03 0.032 0.054 0.062 0.062C0.181 0.645 0.4 0.645 0.4 0.645s0.219 0 0.274 -0.014a0.088 0.088 0 0 0 0.062 -0.062C0.75 0.514 0.75 0.4 0.75 0.4s0 -0.114 -0.014 -0.168"
    />
    <path fill="#ffffff" d="m0.33 0.505 0.182 -0.105 -0.182 -0.105z" />
  </svg>
);

export const NextIcon = (props) => (
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 38.4 38.4"
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.68 4 11.2 6.48l12.72 12.72 -12.72 12.72 2.48 2.48L28.8 19.2z"
      fill="#fff"
    />
  </svg>
);

export const Check = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path
      d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
      fill="#fff"
    />
  </svg>
);

export const DownArrow = (props) => (
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 25 25"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#fff"
      d="M19.039 8.736A1.389 1.389 0 0 0 18.056 8.333H6.951A1.389 1.389 0 0 0 5.972 10.708l5.556 5.556a1.407 1.407 0 0 0 1.972 0l5.556 -5.556a1.403 1.403 0 0 0 -0.018 -1.972z"
    />
  </svg>
);

export const CrossIcon = (props) => (
  <svg
    fill="#fff"
    width="20px"
    height="20px"
    viewBox="0 0 38.4 38.4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.218 15.821 31.901 3.173c0.938 -0.936 2.458 -0.934 3.394 0.004s0.934 2.458 -0.004 3.394L22.614 19.214 35.285 31.875c0.938 0.937 0.938 2.457 0.001 3.394s-2.457 0.938 -3.394 0.001L19.215 22.604 6.533 35.251c-0.938 0.936 -2.458 0.934 -3.394 -0.004s-0.934 -2.458 0.004 -3.394l12.676 -12.642L3.155 6.558c-0.938 -0.937 -0.938 -2.457 -0.001 -3.394s2.457 -0.938 3.394 -0.001z" />
  </svg>
);

export const WalletIcon = (props) => (
  <svg
    fill="#fff"
    width="40px"
    height="40px"
    viewBox="0 0 1.2 1.2"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M0.9 0.25h0.1a0.1 0.1 0 0 1 0.1 0.1v0.6a0.1 0.1 0 0 1 -0.1 0.1H0.2a0.1 0.1 0 0 1 -0.1 -0.1h0.001A0.1 0.1 0 0 1 0.1 0.934V0.338a0.1 0.1 0 0 1 0.072 -0.096l0.6 -0.171A0.1 0.1 0 0 1 0.9 0.166zm0 0.1v0.412a0.1 0.1 0 0 1 -0.072 0.096L0.507 0.95H1V0.35zM0.2 0.338v0.596l0.6 -0.171V0.166zM0.9 0.55V0.45h0.1v0.1zm-0.25 -0.05a0.05 0.05 0 1 1 0 -0.1 0.05 0.05 0 0 1 0 0.1"
    />
  </svg>
);

export const GiftImg = (props) => (
  <svg
    width="80px"
    height="80px"
    viewBox="0 0 4 4"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path
      fill="#98fd63"
      d="M2.525 1.25c0.5 -0.075 0.975 -0.275 0.55 -0.9 -0.175 -0.25 -0.35 -0.35 -0.5 -0.35 -0.25 0 -0.425 0.275 -0.575 0.55C1.85 0.275 1.675 0 1.425 0c-0.15 0 -0.325 0.1 -0.5 0.35 -0.45 0.625 0.05 0.825 0.55 0.9H0v0.75h4V1.25zm0.05 -1c0.025 0 0.125 0.025 0.3 0.25 0.125 0.175 0.15 0.275 0.125 0.325 -0.05 0.075 -0.325 0.175 -0.825 0.2 0 -0.05 -0.025 -0.1 -0.05 -0.15C2.275 0.525 2.45 0.25 2.575 0.25M1 0.825c-0.025 -0.05 0 -0.15 0.125 -0.325 0.175 -0.225 0.275 -0.25 0.3 -0.25 0.125 0 0.3 0.275 0.45 0.625 -0.025 0.05 -0.05 0.1 -0.05 0.15 -0.5 -0.025 -0.775 -0.125 -0.825 -0.2M1.75 1.75V1.25h0.5v0.5z"
    />
    <path fill="#98fd63" d="M2.25 3.75H1.75V2.25H0.25v1.75h3.5V2.25H2.25z" />
  </svg>
);

export const LeftIcon = (props) => (
  <svg
    fill="#fff"
    width="30px"
    height="30px"
    viewBox="-2.944 0 19.2 19.2"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"left"}</title>
    <path d="m9.637 2.4 1.275 1.275 -6.112 6.15 6.112 6.15 -1.275 1.275L2.288 9.825z" />
  </svg>
);

export const RightIcon = (props) => (
  <svg
    fill="#fff"
    width="30px"
    height="30px"
    viewBox="-2.887 0 19.2 19.2"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"right"}</title>
    <path d="m3.675 17.25 -1.275 -1.275 6.112 -6.15L2.4 3.675l1.275 -1.275 7.35 7.425z" />
  </svg>
);

export const BinanceIcon = (props) => (
  <svg
    fill="#f3ba2f"
    width="30px"
    height="30px"
    viewBox="0 0 1.2 1.2"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"binance"}</title>
    <path d="m0.599 0.038 0.345 0.343 -0.127 0.127 -0.217 -0.217 -0.217 0.218 -0.127 -0.127zM0.165 0.473 0.291 0.6l-0.126 0.126L0.038 0.6zm0.435 0 0.127 0.126 -0.127 0.127L0.472 0.6zm0.434 0L1.162 0.6l-0.127 0.127L0.908 0.6zm-0.217 0.217 0.127 0.127 -0.345 0.345 -0.345 -0.345 0.127 -0.127 0.217 0.218z" />
  </svg>
);

export const EnergyIcon = (props) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="30px"
    height="30px"
    viewBox="0 0 40 40"
    style={{
      enableBackground: "new 0 0 40 40",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        d="M30.5 0H15.499L9.501 21.596h6.01L10.401 40h1.8l17.397 -21.597H20.785Z"
        fill="#E6B1BA"
      />
    </g>
  </svg>
);

export const EnergyIconLarge = (props) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100px"
    height="100px"
    viewBox="0 0 40 40"
    style={{
      enableBackground: "new 0 0 40 40",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        d="M30.5 0H15.499L9.501 21.596h6.01L10.401 40h1.8l17.397 -21.597H20.785Z"
        fill="#E6B1BA"
      />
    </g>
  </svg>
);

export const TalentsIcon = () => {
  return (
    <svg
      id="Layer_1"
      enable-background="new 0 0 512 512"
      fill="#000000"
      width="30px"
      height="30px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clip-rule="evenodd"
        d="m133.878 496 244.244-.001c6.342 0 11.047-5.734 9.809-11.954-12.281-61.643-66.679-108.102-131.931-108.102-65.253 0-119.651 46.459-131.932 108.102-1.238 6.221 3.467 11.955 9.81 11.955zm237.41-351.098c-3.814 2.192-8.683.877-10.875-2.938-2.192-3.814-.876-8.683 2.938-10.875l54.539-31.488c3.814-2.192 8.683-.876 10.875 2.938s.876 8.683-2.938 10.875zm13.252 65.364c-4.418 0-8-3.582-8-8s3.582-8 8-8h62.976c4.418 0 8 3.582 8 8s-3.582 8-8 8zm-21.19 63.176c-3.814-2.192-5.129-7.061-2.938-10.875 2.192-3.814 7.061-5.129 10.875-2.938l54.539 31.488c3.814 2.192 5.129 7.061 2.938 10.875-2.192 3.814-7.061 5.129-10.875 2.938zm-222.638-13.813c3.814-2.192 8.683-.877 10.875 2.938 2.192 3.814.877 8.683-2.938 10.875l-54.538 31.488c-3.814 2.192-8.683.876-10.875-2.938s-.876-8.683 2.938-10.875zm7.938-128.54c3.814 2.192 5.129 7.061 2.938 10.875-2.192 3.814-7.061 5.129-10.875 2.938l-54.539-31.488c-3.814-2.192-5.129-7.061-2.938-10.875 2.192-3.814 7.061-5.129 10.875-2.938zm-21.19 63.177c4.418 0 8 3.582 8 8s-3.582 8-8 8h-62.976c-4.418 0-8-3.582-8-8s3.582-8 8-8zm132.29-175.694 19.253 50.71c.579 1.524 1.926 2.503 3.555 2.582l54.177 2.641c3.685.179 5.193 4.821 2.318 7.132l-42.278 33.981c-1.271 1.021-1.786 2.605-1.358 4.178l14.23 52.341c.968 3.56-2.98 6.428-6.067 4.408l-45.383-29.708c-1.364-.893-3.029-.893-4.393 0l-45.382 29.708c-3.086 2.02-7.035-.848-6.067-4.408l14.23-52.342c.428-1.573-.087-3.157-1.357-4.179l-42.278-33.981c-2.875-2.311-1.367-6.953 2.318-7.132l54.178-2.641c1.629-.079 2.976-1.058 3.554-2.582l19.253-50.71c1.299-3.427 6.195-3.427 7.497.002zm-3.749 211.331c37.566 0 68.02 30.454 68.02 68.02s-30.454 68.02-68.02 68.02-68.02-30.454-68.02-68.02 30.454-68.02 68.02-68.02z"
        fill-rule="evenodd"
      />
    </svg>
  );
};
