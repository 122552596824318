import React, { useContext, useEffect, useState } from "react";
import "./tabCard.css";
import cardImg from "../../../../assets/tabCard.png";
import {
  CoinIcon,
  CrossIcon,
  // WalletIcon,
} from "../../../../common-componants/icons/icons";
import UserContext from "../../../../context/userContext";
import { userInstance } from "../../../../config/axios";
// import { BuyPopUp } from "../../buyPopUp";
import bunnyCar from "../../../../assets/bunnyCar.png";
import goldCoin from "../../../../assets//gold-coin.png";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { formatNumberWithCommas, formatToK } from "../../utils";
import FlyCoins from "../flyCoins";
import FlyCoinsSec from "../flyCoinsSec";

function TabCard({ mineType }) {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [mines, setMines] = useState([]);
  const [selectedMines, setSelectedMines] = useState([]);
  const { user, setUser, walletVisible ,hapticEnabled} = useContext(UserContext);
  const [updatedUser, setUpdatedUser] = useState(user);
  const [loading, setLoading] = useState(true);
  const [buyClicked, setBuyClicked] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showAnimation2, setShowAnimation2] = useState(false);

  const getMines = async () => {
    try {
      if (user) {
        try {
          const resp = await userInstance().post("/mines", {
            userId: user?._id,
            mineType,
          });
          setMines(resp.data);
        } catch (error) {
          toast.error(error.message, { toastId: "getMines" });
        }
      }
    } catch (error) {
      toast.error(error.message, { toastId: "getMines" });
      console.error("Failed to fetch mines", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      getMines();
    }
  }, []);

  // useEffect(() => {
  //   setMines((old) =>
  //     old.map((el) => {
  //       const exists = user?.minutlyGain?.subscribed?.findIndex(
  //         (el2) => el2._id.toString() === el._id.toString()
  //       );
  //       if (exists > -1) return user?.minutlyGain?.subscribed[exists];
  //       return el;
  //     })
  //   );
  //   // resp.data.map
  // }, [user]);

  const showPopup = (data) => {
    // if (user.tokens < data?.cost) return;
    setIsPopupVisible(true);
    setSelectedMines(data);
  };

  const hidePopup = () => {
    setIsPopupVisible(false);
  };

  // console.log("mines", mines);

  const isLocked = (data, userSubscribed) => {
    if (data.dependency) {
      if (userSubscribed?.level >= data.dependency.requiredLevel) {
        return false;
      } else {
        return true;
      }
    }
  };

  const confirmBuy = async () => {
    if (!buyClicked) {
      if (walletVisible) {
        setShowAnimation2(false);
        setShowAnimation(true);
      } else {
        setShowAnimation(false);
        setShowAnimation2(true);
      }
      try {
        setBuyClicked(true);

        const resp = await userInstance().post("/buyMines", {
          userId: user._id,
          mine: selectedMines,
        });
        setUser(resp.data);
        setUpdatedUser(resp.data);
        getMines();
        
        setBuyClicked(false);

        hidePopup();
      } catch (error) {
        toast.error(error.message, { toastId: "confirmBuy" });
      }
    }
  };

  useEffect(() => {
    if (showAnimation) {
      const timer = setTimeout(() => {
        if (window.Telegram.WebApp && hapticEnabled) {
          const { WebApp } = window.Telegram;
          WebApp.ready();
          const noti = WebApp.HapticFeedback;
          noti.notificationOccurred("success");
        }
        setShowAnimation(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showAnimation]);

  useEffect(() => {
    let intervalId;
    if (showAnimation) {
      intervalId = setInterval(() => {
        if (window.Telegram.WebApp && hapticEnabled) {
          const { WebApp } = window.Telegram;
          WebApp.ready();
          const noti = WebApp.HapticFeedback;
          noti.notificationOccurred("success");
        }
      }, 100); 
      const timer = setTimeout(() => {
        setShowAnimation(false); 
        clearInterval(intervalId); 
      }, 2000); 
      return () => {
        clearTimeout(timer); 
        clearInterval(intervalId);
      };
    }
  }, [showAnimation]);
  

  useEffect(() => {
    let intervalId;
    if (showAnimation2) {
      intervalId = setInterval(() => {
        if (window.Telegram.WebApp && hapticEnabled) {
          const { WebApp } = window.Telegram;
          WebApp.ready();
          const noti = WebApp.HapticFeedback;
          noti.notificationOccurred("success");
        }
      }, 100); 
      const timer = setTimeout(() => {
        setShowAnimation2(false);
      }, 2000);
      return () => {
        clearTimeout(timer); 
        clearInterval(intervalId);
      };
    }
  }, [showAnimation2]);

  return (
    <>
      {loading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <div className=" row row-gap-2 tabCardWrap-main  ">
          {mines?.map((data) => {
            //const a = user?.minutlyGain?.subscribed
            const userSubscribed =
              data.dependency &&
              user?.minutlyGain?.subscribed?.find(
                (mine) => mine.designation === data.dependency.mine
              );
            const isLock = isLocked(data, userSubscribed);

            return (
              <div
                className="tabCardWrap col-6 d-flex justify-content-between px-1 "
                key={data?._id}
              >
                {isLock && data.dependency ? (
                  <div className="tabCard locked-tabCard">
                    <div className="tabCardTopWrap d-flex align-items-center">
                      <p className="text-white ">
                        <SVGLockComponent />
                      </p>
                      <div>
                        <h2 className="text-grey">{data?.designation}</h2>
                        <p>Profit per hour</p>
                        <span>
                          <img src={goldCoin} alt="" className="coinHeight" /> +
                          {formatToK(data?.pph)}
                        </span>
                      </div>
                    </div>
                    <div className="tabCardBottomWrap">
                      <div className="d-flex align-items-center justify-content-between column-gap-3">
                        <span className="vsTag">lvl {data?.level}</span>
                        <span className="d-flex bottomWrapSpan text-white">
                          <img src={goldCoin} alt="" className="coinHeight" />{" "}
                          <p>
                            {`${data.dependency?.mine} lvl 
                            ${data.dependency?.requiredLevel}`}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="tabCard" onClick={() => showPopup(data)}>
                    <div className="tabCardTopWrap d-flex align-items-center">
                      <img src={data.imgUrl ? data.imgUrl : cardImg} alt="" />
                      <div>
                        {data?.designation === 'Times Slow Embrace' ? <h2>Time's Slow Embrace</h2> : <h2>{data?.designation}</h2> }
                        
                        <p>Profit per hour</p>
                        <span>
                          <img src={goldCoin} alt="" className="coinHeight" />
                          {/* <CoinIcon className="coinHeight" /> */}+
                          {data?.level===1 ? formatToK(data?.pph)
                          :
                          (formatToK((data?.pph/data?.level)*(data?.level-1)))
                          }
                        </span>
                      </div>
                    </div>
                    <div className="tabCardBottomWrap">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="vsTag">lvl {(data?.level - 1)}</span>
                        <span className="bottomWrapSpan">
                          <img src={goldCoin} alt="" className="coinHeight" />
                          {formatToK(data?.cost)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className="tabCard">
                <div className="tabCardTopWrap d-flex align-items-center">
                  <img src={cardImg} alt="" />
                  <div>
                    <h2>CEO</h2>
                    <p>Profit per hour</p>
                    <span>
                      <CoinIcon className="coinHeight" /> +100
                    </span>
                  </div>
                </div>
                <div className="tabCardBottomWrap">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="vsTag">|v| 0</span>
                    <span className="bottomWrapSpan">
                      <CoinIcon className="coinHeight" /> 1K
                    </span>
                  </div>
                </div>
              </div> */}
              </div>
            );
          })}
        </div>
      )}
      {isPopupVisible && (
        <div className="deletePopupWrap tabCrdPopup cardRoundedWrap pt-3 pb-5 px-3 pl-0">
          <div className="crossBtnWrap">
            <span onClick={hidePopup}>
              <CrossIcon />
            </span>
          </div>
          <div className="walletWrap mineImgPosition text-center mt-5 popImg">
            {/* <span className="circlePopup"></span> */}
            {/* <div className="imgPosition"> */}
            <img src={selectedMines.imgUrl || bunnyCar} alt="" />
            {/* </div> */}
            {selectedMines?.designation === 'Times Slow Embrace' ? <h2>Time's Slow Embrace</h2> : <h2>{selectedMines?.designation}</h2> }

            {/* <h1 className="">{selectedMines?.designation}</h1> */}
            {selectedMines?.specialSlogan ? (
              <p>{selectedMines?.specialSlogan}</p>
            ) : (
              <p>Upgrade your {selectedMines.designation} to next level</p>
            )}
          </div>
          <div className="btnsDivWrap airDropsBtnWrap mt-3">
            <div className="text-center mineNumberWrap">
              <p>
                <img src={goldCoin} alt="" />
                {formatToK(selectedMines?.cost)}
              </p>
            </div>
            {updatedUser.tokens < selectedMines?.cost ? (
              <button>Insufficient balance</button>
            ) : (
              <>
                <button
                  class="btn-main js-btn "
                  id="button"
                  onClick={() => confirmBuy()}
                >
                  Go ahead
                </button>
              </>
            )}
          </div>
        </div>
      )}
      {showAnimation && <FlyCoins />}
      {showAnimation2 && <FlyCoinsSec />}
    </>
  );
}

export default TabCard;

export const SVGLockComponent = (props) => (
  <svg
    fill="#fff"
    width="20px"
    height="20px"
    viewBox="-0.088 0 0.475 0.475"
    xmlns="http://www.w3.org/2000/svg"
    className="cf-icon-svg"
    {...props}
  >
    <path d="M0.28 0.223v0.173a0.02 0.02 0 0 1 -0.02 0.02H0.04a0.02 0.02 0 0 1 -0.02 -0.02V0.223a0.02 0.02 0 0 1 0.02 -0.02h0.021V0.159a0.088 0.088 0 1 1 0.177 0v0.044h0.021a0.02 0.02 0 0 1 0.02 0.02m-0.069 -0.064a0.061 0.061 0 1 0 -0.121 0v0.044h0.121zM0.178 0.287a0.028 0.028 0 1 0 -0.04 0.025v0.046a0.012 0.012 0 0 0 0.024 0v-0.046a0.028 0.028 0 0 0 0.016 -0.025" />
  </svg>
);
