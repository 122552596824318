import React, { useEffect, useRef, useCallback, useContext } from "react";
import "./SpinLucky.css";
import { useState } from "react";
import { useAdsgram } from "../../../../utils/useAdsgram";
import Img1 from "./../../../../assets/winCoinsOne.png";
import Img2 from "./../../../../assets/winCoinsTwo.png";
import Img3 from "./../../../../assets/winCoinsThree.png";
import Img4 from "./../../../../assets/winCoinsFour.png";
import Img5 from "./../../../../assets/winCoinsFive.png";
import Img6 from "./../../../../assets/winCoinsSix.png";
import Img7 from "./../../../../assets/winCoinsSeven.png";
import Img8 from "./../../../../assets/winCoinsEight.png";
import Img9 from "./../../../../assets/winCoinsNine.png";
import Img10 from "./../../../../assets/luckytext.png";
import LuckyDraw from "./../../../../assets/luckydraw.png";
import { CrossIcon } from "../../../../common-componants/icons/icons";
import { userInstance } from "../../../../config/axios";
import UserContext from "../../../../context/userContext";
import useUpgradeUserLevel from "../../../../utils/useUpgradeUserLevel";
import { Spinner } from "react-bootstrap";
import FlyCoinsSec from "./../../../mine/componants/flyCoinsSec";

const SpinLucky = ({ setWheel, setSpinResult }) => {
  const { user, setUser } = useContext(UserContext);
  const [activeBox, setActiveBox] = useState(1); // Track the active box (starts from 1)
  const [isSpinning, setIsSpinning] = useState(false); // Track if the game is running
  const [winner, setWinner] = useState(null); // Store the winning box value
  const [spinNow, setSpinNow] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);

  useUpgradeUserLevel(user, setUser);

  const onReward = useCallback(() => {
    setSpinNow(true);
  }, []);

  const retryCountRef = useRef(0);
  const onError = useCallback((result) => {
    if (retryCountRef.current < 4) {
      retryCountRef.current += 1;
      showAd();
    } else {
      console.log("No Ads available", result);
    }
  }, []);

  // 5882 is block id of adsgram-------
  const showAd = useAdsgram({ blockId: "5890", onReward, onError });

  const boxes = [
    { id: 1, value: 25000, src: Img1 },
    { id: 2, value: 7000, src: Img2 },
    { id: 3, value: 0, src: Img10 },
    { id: 4, value: 27000, src: Img3 },
    { id: 5, value: 15000, src: Img4 },
    { id: 6, value: 1000, src: Img8 },
    { id: 7, value: 10000, src: Img6 },
    { id: 8, value: 18000, src: Img1 },
    { id: 9, value: 0, src: Img10 },
    { id: 10, value: 30000, src: Img7 },
    { id: 11, value: 22000, src: Img5 },
    { id: 12, value: 3000, src: Img9 },
  ];

  const playGame = () => {
    if (isSpinning) return; // Prevent multiple clicks
    setIsSpinning(true);
    setWinner(null); // Reset winner

    let currentIndex = winner ? winner.id - 1 : 0; // Start from winner or first box
    const spinDuration = Math.random() * 2000 + 2000; // Random spin time (2-4 seconds)

    const interval = setInterval(() => {
      setActiveBox(boxes[currentIndex].id); // Set active box
      currentIndex = (currentIndex + 1) % boxes.length; // Move to the next box
    }, 200);

    setTimeout(() => {
      clearInterval(interval); // Stop spinning
      const winningBox = boxes[currentIndex];
      setActiveBox(winningBox.id);
      setWinner(winningBox); // Declare the winner
      setSpinResult(winningBox);
      setIsSpinning(false);
      setSpinNow(false);
      // Call API to update user rewards
      collectReward(winningBox.value);
    }, spinDuration);
  };

  const getServerTime = async () => {
    setLoading(true);
    const resp = await userInstance().get("/getTime");
    return resp?.data;
  };

  useEffect(() => {
    const initializeSpinState = async () => {
      try {
        const serverTime = await getServerTime(); // Fetch the current server time
        const lastSpin = user?.lastSpinTime; // Get the user's last spin time

        if (!lastSpin) {
          // No last spin time, allow user to play immediately
          setTimeLeft(0); // No cooldown
          setLoading(false);
        } else {
          // Calculate the cooldown time
          const nextSpinTime = new Date(lastSpin).getTime() + 1200000; // 20 minute cooldown

          const remainingTime = nextSpinTime - new Date(serverTime).getTime();

          // If the cooldown is over, allow the user to play immediately
          setTimeLeft(Math.max(remainingTime, 0));
          setLoading(false);
        }
      } catch (err) {
        console.error("Failed to fetch server time:", err);
        setTimeLeft(0); // Fallback to allow play if server time fetch fails
        setLoading(false);
      }
    };

    initializeSpinState();
  }, []);

  useEffect(() => {
    if (timeLeft > 0) {
      const interval = setInterval(() => {
        setTimeLeft((prevTime) => Math.max(prevTime - 1000, 0)); // Decrement by 1 second
      }, 1000);

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [timeLeft]);

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const collectReward = async (amount) => {
    try {
      setLoading(true);
      const resp = await userInstance().post("/collectSpinReward", {
        userId: user._id,
        amount,
      });

      setUser(resp.data); // Update user tokens
    } catch (err) {
      console.error("Failed to collect reward:", err);
      setLoading(false);
    } finally {
      setTimeout(() => {
        setLoading(false);
        setWheel(false); // Close modal after reward
      }, 3000); // 3 seconds delay for better UX
    }
  };

  useEffect(() => {
    if (spinNow) {
      playGame();
    }
  }, [spinNow]);

  const handleClose = () => {
    setWheel(false);
  };

  const HnadleTooltip = () => {
    setShowMsg(!showMsg);
  };

  return (
    <div className="SpinLucky-container">
      <div className="SpinLucky-wrapper">
        <div className="Spinlucky-info" onClick={HnadleTooltip}>
          <InfoSVG />
          {showMsg ? (
            <div className="toltip">
              <div className="corn"></div>
              <p>
                These ads are for project growth and not related to us. Always
                DYOR
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="SpinLucky-cross-menus" onClick={handleClose}>
          <CrossIcon />
        </div>
        <div className="luckydraw">
          <img src={LuckyDraw} alt="" />
        </div>
        <div className="rectangle">
          {boxes.map((box) => (
            <div
              key={box.id}
              className={`box1 ${activeBox === box.id ? "active" : ""}`}
            >
              <img src={box.src} alt="" />
              {/* <p>{box.id}</p> */}
              {box.value > 0 ? <h4>{box.value / 1000}K</h4> : null}
            </div>
          ))}

          <div className="SpinLucky-watch-text">
            <span>Watch video</span>
            <span>Get free spin</span>
          </div>
          {!loading ? (
            timeLeft > 0 ? (
              <button>{formatTime(timeLeft)}</button>
            ) : (
              <button onClick={showAd} disabled={isSpinning}>
                {isSpinning ? (
                  <div className="d-flex justify-content-center align-items-center  ">
                    <Spinner animation="border" variant="light" />
                  </div>
                ) : (
                  "Free"
                )}
              </button>
            )
          ) : (
            <button>
              <div className="d-flex justify-content-center align-items-center  ">
                <Spinner animation="border" variant="light" />
              </div>
            </button>
          )}

          {winner && winner.value > 0 && <FlyCoinsSec />}
        </div>
      </div>
    </div>
  );
};

export default SpinLucky;

export const InfoSVG = () => {
  return (
    <svg
      width="24px"
      height="24x"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Warning / Info">
        <path
          id="Vector"
          d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
          stroke="#fff"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};
