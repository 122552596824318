import React, { useContext, useEffect, useState, useCallback } from "react";
import "./level.css";
import Slider from "react-slick";
import SliderImg from "../../assets/bunnie.png";
import bunnyImg from "../../assets/profile/bunnyImg.png";
import {
  CoinIcon,
  LeftIcon,
  NextIcon,
  RightIcon,
  YoutubeRed,
} from "../../common-componants/icons/icons";
import { userInstance } from "../../config/axios";
import UserContext from "../../context/userContext";

import goldCoin from "../../assets//gold-coin.png";
import { formatNumberWithCommas, formatToK } from "../mine/utils";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Bronze from "../../assets/Leaderboard/Bronze.jpg";
import Silver from "../../assets/Leaderboard/Silver.jpg";
import Gold from "../../assets/Leaderboard/Gold.jpg";
import Platinum from "../../assets/Leaderboard/Platinum.jpg";
import Diamond from "../../assets/Leaderboard/Diamond.jpg";
import Epic from "../../assets/Leaderboard/Epic.jpg";
import Legendry from "../../assets/Leaderboard/Legendary.jpg";
import Master from "../../assets/Leaderboard/Master.jpg";
import Grand_Master from "../../assets/Leaderboard/Grand Master.jpg";
import Lord from "../../assets/Leaderboard/Lord.jpg";
import Creator from "../../assets/Leaderboard/Creator.jpg";

function LevelBronze() {
  const [levels, setLevels] = useState([]);
  const { user } = useContext(UserContext);
  // const [slidesStart, setSlidesStart] = useState();
  const [currentSlide, setCurrentSlide] = useState(user?.level);
  const [currentSlideName, setCurrentSlideName] = useState(user?.level.name);

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1); // Track the current page
  const [hasMore, setHasMore] = useState(true);
  // const [fetchedLevels, setFetchedLevels] = useState({});
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const profileImagesByName = {
    Bronze: Bronze,
    Silver: Silver,
    Gold: Gold,
    Platinum: Platinum,
    Diamond: Diamond,
    Epic: Epic,
    Legendry: Legendry,
    Master: Master,
    "Grand Master": Grand_Master,
    Lord: Lord,
    Creator: Creator,
  };

  useEffect(() => {
    if (window.Telegram.WebApp && location.pathname !== "/") {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => {
        window.history.back();
        WebApp.BackButton.hide();
      });
    }
  }, []);

  // const exchanges = [
  //   { name: "Zeus", Icon: Zeus },
  //   { name: "Hera", Icon: Hera },
  //   { name: "Poseidon", Icon: Poseidon },
  //   { name: "Athena", Icon: Athena },
  //   { name: "Krishna", Icon: Krishna },
  //   { name: "Varaha", Icon: Varaha },
  //   { name: "Rama", Icon: Rama },
  //   { name: "Odin", Icon: Odin },
  //   { name: "Loki", Icon: Loki },
  //   { name: "Thor", Icon: Thor },
  //   { name: "Balder", Icon: Balder },
  //   { name: "Sun Wukong", Icon: Wukong },
  // ];

  useEffect(() => {
    setLoading2(true);
    (async () => {
      const resp = await userInstance().get("/levels");
      // console.log("resp ==>", resp.data);
      const sortedLevels = resp.data.levels.sort(
        (a, b) => a.levelNo - b.levelNo
      );
      setLevels(sortedLevels);
      setLoading2(false);
    })();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, []);

  const levelNames = [
    "Bronze",
    "Silver",
    "Gold",
    "Platinum",
    "Diamond",
    "Epic",
    "Legendry",
    "Master",
    "Grand Master",
    "Lord",
    "Creator",
  ];

  const fetchUsers = useCallback(async () => {
    if (loading) return;

    try {
      setLoading(true);
      const resp = await userInstance().post("/getAllUsersByLevel", {
        page,
        limit: 10,
      });

      // Update the `users` state for each level
      setUsers((prev) => {
        const updatedUsers = { ...prev }; // Copy the previous state

        for (const level in resp.data) {
          const { users: newUsers } = resp.data[level];

          // Initialize the level if it doesn't exist
          if (!updatedUsers[level]) {
            updatedUsers[level] = [];
          }

          const existingUsers = updatedUsers[level];

          // Merge the existing users with the new ones, ensuring no duplicates by _id
          const uniqueUsers = [...existingUsers, ...newUsers]
            .reduce((acc, user) => {
              acc.set(user._id, user);
              return acc;
            }, new Map())
            .values();

          // Convert the Map back to an array and ensure we don't exceed 500 users
          updatedUsers[level] = Array.from(uniqueUsers).slice(0, 500);
        }

        return updatedUsers; // Return the updated object
      });

      // Update `hasMore` state for each level independently
      setHasMore((prev) => {
        const updatedHasMore = { ...prev }; // Copy the previous state

        // Iterate over levelNames to ensure all levels are handled
        for (const level of levelNames) {
          if (resp.data[level]) {
            updatedHasMore[level] = resp.data[level].hasMore; // Use the response value if available
          } else {
            updatedHasMore[level] = false; // Explicitly set `hasMore` to false if the level is missing
          }
        }

        return updatedHasMore;
      });

      // Increment the page only for the current slide
      setPage((prev) => prev + 1);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, page, users]);

  useEffect(() => {
    if (hasMore[currentSlideName]) {
      setHasMore((prevHasMore) => {
        const updatedHasMore = { ...prevHasMore };

        // Loop through the levels in `users`
        for (const level in users) {
          const userCount = users[level]?.length || 0;

          // Update `hasMore` for the level if the user count reaches 500
          updatedHasMore[level] = userCount < 500;
        }

        return updatedHasMore;
      });
    }
  }, [users]);

  const clearUsers = () => {
    setUsers([]);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: (user?.level?.levelNo || 1) - 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    afterChange: (index) => {
      setCurrentSlideName(levels[index]?.name);
      return setCurrentSlide(index + 1);
    },
    beforeChange: (index) => {
      setCurrentSlideName(levels[index]?.name);
      return setCurrentSlide(index + 1);
    },
  };

  function SamplePrevArrow(props) {
    const { className, style, onClick, clearUsers } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "none" }}
        onClick={() => {
          // clearUsers();
          onClick();
        }}
      >
        <LeftIcon />
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick, clearUsers } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "none" }}
        onClick={() => {
          // clearUsers();
          onClick();
        }}
      >
        <RightIcon />
      </div>
    );
  }
  const sliderRepeat = levels; //[1, 2, 3, 4, 5];
  const gradients = [
    "radial-gradient(circle, rgba(205, 127, 50, 0.8), rgba(139, 69, 19, 0.18), rgba(110, 54, 19, 0))",
    "radial-gradient(circle, rgba(192, 192, 192, 0.8), rgb(169 169 169 / 18%), rgba(128, 128, 128, 0))",
    "radial-gradient(circle, rgba(255, 215, 0, 0.8), rgba(218, 165, 32, 0.18), rgba(184, 134, 11, 0))",
    "radial-gradient(circle, rgba(221, 239, 255, 0.9), rgba(187, 222, 251, 0.25), rgba(156, 204, 235, 0))",

    "radial-gradient(circle, rgba(173, 216, 230, 0.8), rgba(100, 149, 237, 0.18), rgba(70, 130, 180, 0))",
    "radial-gradient(circle, rgba(144, 238, 144, 0.8), rgba(60, 179, 113, 0.18), rgba(34, 139, 34, 0))",
    "radial-gradient(circle, rgba(255, 182, 193, 0.8), rgba(255, 160, 122, 0.18), rgba(255, 105, 97, 0))",
    "radial-gradient(circle, rgba(255, 182, 193, 0.8), rgba(255, 105, 180, 0.18), rgba(255, 192, 203, 0))",

    // new colors
    "radial-gradient(circle, rgba(255, 228, 181, 0.8), rgba(255, 165, 0, 0.18), rgba(255, 140, 0, 0))",
    "radial-gradient(circle, rgba(147, 112, 219, 0.8), rgba(138, 43, 226, 0.18), rgba(75, 0, 130, 0))",
    "radial-gradient(circle, rgba(224, 255, 255, 0.8), rgba(175, 238, 238, 0.18), rgba(0, 206, 209, 0))",
  ];
  return (
    <div className="levelWrapPadding">
      {loading2 ? (
        <div
          className="d-flex justify-content-center align-items-center  "
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" variant="light" />
        </div>
      ) : (
        <div className="levelMainWrap">
          <div className="levelSliderWrap">
            <Slider {...settings}>
              {sliderRepeat?.map((lvl, index) => (
                <div key={lvl.levelNo}>
                  <div
                    id="sliderWrap"
                    className="sliderWrap text-center m-auto px-3"
                    style={{
                      height: "100vh",
                      backgroundImage: gradients[index % gradients.length],
                    }}
                  >
                    <img
                      src={profileImagesByName[lvl?.name] || SliderImg}
                      className={
                        profileImagesByName[lvl?.name] ? "dynamic-images" : ""
                      }
                      alt=""
                    />

                    <div className="sliderContentWrap">
                      <h1>{lvl?.name==='Legendry' ? 'Legendary' : lvl?.name}</h1>
                      <span>{lvl?.value}</span>
                      {/* {console.log('HasMore:', hasMore)} */}
                      {users[lvl?.name]?.map((mappedUser, idx) => (
                        <div
                          key={idx}
                          className={`cardSection sliderCardSection p-2 mt-3 d-flex align-items-center position-relative ${
                            user?._id === mappedUser?._id
                              ? "activeAbsoluteCard"
                              : ""
                          }`}
                        >
                          <div className="sideImgWrapLavel">
                            <img
                              src={mappedUser?.profileImage || SliderImg}
                              alt="sideImgWrapLavel1"
                            />
                          </div>
                          <div className="contentWrap">
                            <div className="d-flex align-items-center">
                              <h2 className="ms-2 mt-2">{mappedUser?.name}</h2>
                            </div>
                            <p className="mb-0 mt-1">
                              <img
                                src={goldCoin}
                                alt=""
                                className="lavelCoinsImg"
                              />
                              &nbsp;{formatToK(parseInt(mappedUser?.tokens))}
                              &nbsp;
                            </p>
                          </div>
                          <div className="nextIcon">
                            <span>{idx + 1}</span>
                          </div>
                        </div>
                      ))}
                      {loading ? (
                        <div className="loader mt-5">
                          <Spinner animation="border" variant="light" />
                        </div>
                      ) : (
                        hasMore[lvl?.name] && (
                          <button
                            className="load-more-btn"
                            onClick={() => fetchUsers()}
                          >
                            Load More
                          </button>
                        )
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
}

export default LevelBronze;
