import React, { useEffect, useState } from "react";
import "./flyCoinsSec.css";

function FlyCoinsSec() {
  const [coinsArray, setCoinsArray] = useState([]);

  useEffect(() => {
    // Randomize x positions and assign animation delay for each coin
    const coins = Array.from({ length: 100 }, () => ({
      x: `calc(50vw + ${Math.random() * 70 - 40}vw)`, // Random x between 0% and 100% of the viewport width
      y: "0%", // Start position at the bottom of the viewport
      delay: `${Math.random() * 0.9 + 0.1}s`, 
    }));
    setCoinsArray(coins);
  }, []);

  return (
    <div className="second wrap">
      <div className="wallet" id="wallet">
        <div className="icon"></div>
        {coinsArray.map((coin, index) => (
          <div
            key={index}
            className="coin coin--animated"
            style={{
              left: coin.x,
              bottom: coin.y,
              animationDelay: coin.delay,
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default FlyCoinsSec;
