import React, { useState } from "react";
import "./tab.css";
import TabCard from "../tabCard";
import TabSpecial from "../tabSpecial";
import { Market } from "../Market";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="tabs d-flex">
        <div
          className={`tab ${activeTab === "tab1" ? "active" : ""}`}
          onClick={() => handleTabClick("tab1")}
        >
          Foundation
        </div>
        <div
          className={`tab ${activeTab === "tab2" ? "active" : ""}`}
          onClick={() => handleTabClick("tab2")}
        >
          Staff Stances
        </div>
        <div
          className={`tab ${activeTab === "tab3" ? "active" : ""}`}
          onClick={() => handleTabClick("tab3")}
        >
          Deities
        </div>
        <div
          className={`tab ${activeTab === "tab4" ? "active" : ""}`}
          onClick={() => handleTabClick("tab4")}
        >
          Spells
        </div>
        {/* <div
          className={`tab ${activeTab === "tab5" ? "active" : ""}`}
          onClick={() => handleTabClick("tab5")}
        >
          Special
        </div> */}
      </div>
      <div className="tab-content mt-3">
        {activeTab === "tab1" && (
          <div>
            <TabCard mineType="PR&Team" />
          </div>
        )}
        {activeTab === "tab2" && <div><Market /></div>}
        {activeTab === "tab3" && <div><TabCard mineType="Legal" /></div>}
        {activeTab === "tab4" && <div><TabCard mineType="Web3" /></div>}
        {activeTab === "tab5" && (
          <div>
            <TabSpecial />
          </div>
        )}
      </div>
    </>
  );
};

export default Tabs;
