import React, { useState, useContext, useEffect } from "react";
import "./setting.css";
import Form from "react-bootstrap/Form";
import { CrossIcon } from "../../common-componants/icons/icons";
import UserContext from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { NextIcon } from "../../common-componants/icons/icons";
import "../earn/earn.css";
import { userInstance } from "../../config/axios";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCamera } from "@fortawesome/free-solid-svg-icons";
import profilePicture from "../../assets/profile.jpeg";

function Setting() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const { user, setUser, hapticEnabled, setHapticEnabled } =
    useContext(UserContext);
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(user?.profileImage || null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (window.Telegram.WebApp && location.pathname !== "/") {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => {
        window.history.back();
        WebApp.BackButton.hide();
      });
    }
  }, [location]);

  const showPopup = () => {
    setIsPopupVisible(true);
  };

  const hidePopup = () => {
    setIsPopupVisible(false);
  };

  const deleteAccount = async () => {
    try {
      const resp = await userInstance().post("/deleteAccount", {
        userId: user.userId,
      });
      setUser(resp.data);
      window.Telegram.WebApp.close();
    } catch (error) {
      toast.error("Error deleting account.");
    }
  };

  const handleToggleChange = (event) => {
    const isEnabled = event.target.checked;
    setHapticEnabled(isEnabled);
    localStorage.setItem("hapticEnabled", isEnabled);
  };

  // Profile Image Update Function
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Allowed file types
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/webp",
        "image/jpg",
      ];
      const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

      if (!allowedTypes.includes(file.type)) {
        toast.error("Only JPG, JPEG, PNG, and WEBP files are allowed.");
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("File size must be under 2 MB.");
        return;
      }
      setProfileImage(file);
      setPreviewImage(URL.createObjectURL(file)); // Show preview
    } else {
      toast.error("No file selected.");
    }
  };

  const updateProfileImage = async () => {
    if (!profileImage) return;

    const formData = new FormData();
    formData.append("profileImage", profileImage);
    formData.append("userId", user.userId);

    setLoading(true);
    try {
      const resp = await userInstance().post("/updateProfileImage", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (resp.data.code === 200) {
        setUser({ ...user, profileImage: resp.data.profileImage });
        toast.success("Profile image updated successfully.", {
          toastId: "Profile",
        });
        setProfileImage(null);
      } else {
        toast.error(resp.data.message, { toastId: "ProfileError" });
      }
    } catch (error) {
      console.error("Error updating profile image:", error);
      toast.error("Only JPG, JPEG, PNG, and WEBP files are allowed.", {
        toastId: "ProfileError",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="settingMainWrap px-3">
        <div className="Settingwrap pt-3">
          <h1>Settings</h1>
        </div>

        {/* Profile Image Section */}
        <div
          className={`profileImageSection d-flex flex-column align-items-center justify-content-center mt-3 ${
            loading ? "loading" : ""
          }`}
        >
          <div className="position-relative d-flex flex-column align-items-center justify-content-center">
            <div className="img-container">
              <img
                src={previewImage || profilePicture}
                alt="Profile"
                className="profileImage"
              />
            </div>
            <div className="profile-icon">
              <CamaraIcon color="#black" />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="mt-2"
              />
            </div>
          </div>

          <button
            onClick={updateProfileImage}
            className="updateImageButton"
            disabled={loading}
          >
            Save
          </button>
        </div>

        <div className="firstaselectDiv">
          <div
            className="cardSection p-2 mt-3 d-flex align-items-center position-relative"
            onClick={() => navigate("/choose-exchange")}
          >
            <div className="contentWrap">
              <h2>Choose Your Deity</h2>
              {user?.selectedExchange ? (
                <p className="mb-0">{user?.selectedExchange}</p>
              ) : (
                <p>Default Deity</p>
              )}
            </div>
            <div className="nextIcon">
              <NextIcon />
            </div>
          </div>
        </div>

        <div className="toggleWrap cardSection px-3 py-3 position-relative mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <p>Vibration</p>
            <label className="switch">
              <input
                onChange={handleToggleChange}
                checked={hapticEnabled}
                type="checkbox"
              />
              <span className="slider"></span>
            </label>
          </div>
        </div>

        <div className="deleteAccountWrap mt-2">
          <button onClick={showPopup}>Delete Account</button>
        </div>
      </div>

      {isPopupVisible && (
        <div className="deletePopupWrap cardRoundedWrap py-5 px-3">
          <div className="crossBtnWrap">
            <span onClick={hidePopup}>
              <CrossIcon />
            </span>
          </div>
          <div className="contentWrappopup text-center">
            <h1>Are you sure you want to delete your account?</h1>
            <p>
              All your data, including game progress, achievements, and
              purchases, will be permanently deleted. This action cannot be
              undone.
            </p>
          </div>
          <div className="btnsDivWrap">
            <button className="deleteBtn" onClick={() => deleteAccount()}>
              Delete account
            </button>
            <button className="cancelBtn" onClick={hidePopup}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Setting;

export const CamaraIcon = () => {
  return (
    <svg
      width="40px"
      height="35px"
      viewBox="0 0 24 24"
      fill="#11111A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.77778 21H14.2222C17.3433 21 18.9038 21 20.0248 20.2646C20.51 19.9462 20.9267 19.5371 21.251 19.0607C22 17.9601 22 16.4279 22 13.3636C22 10.2994 22 8.76721 21.251 7.6666C20.9267 7.19014 20.51 6.78104 20.0248 6.46268C19.3044 5.99013 18.4027 5.82123 17.022 5.76086C16.3631 5.76086 15.7959 5.27068 15.6667 4.63636C15.4728 3.68489 14.6219 3 13.6337 3H10.3663C9.37805 3 8.52715 3.68489 8.33333 4.63636C8.20412 5.27068 7.63685 5.76086 6.978 5.76086C5.59733 5.82123 4.69555 5.99013 3.97524 6.46268C3.48995 6.78104 3.07328 7.19014 2.74902 7.6666C2 8.76721 2 10.2994 2 13.3636C2 16.4279 2 17.9601 2.74902 19.0607C3.07328 19.5371 3.48995 19.9462 3.97524 20.2646C5.09624 21 6.65675 21 9.77778 21ZM12 9.27273C9.69881 9.27273 7.83333 11.1043 7.83333 13.3636C7.83333 15.623 9.69881 17.4545 12 17.4545C14.3012 17.4545 16.1667 15.623 16.1667 13.3636C16.1667 11.1043 14.3012 9.27273 12 9.27273ZM12 10.9091C10.6193 10.9091 9.5 12.008 9.5 13.3636C9.5 14.7192 10.6193 15.8182 12 15.8182C13.3807 15.8182 14.5 14.7192 14.5 13.3636C14.5 12.008 13.3807 10.9091 12 10.9091ZM16.7222 10.0909C16.7222 9.63904 17.0953 9.27273 17.5556 9.27273H18.6667C19.1269 9.27273 19.5 9.63904 19.5 10.0909C19.5 10.5428 19.1269 10.9091 18.6667 10.9091H17.5556C17.0953 10.9091 16.7222 10.5428 16.7222 10.0909Z"
        fill="#11111A"
      />
    </svg>
  );
};
