import React, { useEffect, useState } from "react";
import "./wallet.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import bunnyImg from "../../../../assets/bunnie.png";
import goldCoin from "../../../../assets/gold-coin.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  formatInt,
  formatNumberWithCommas,
  formatToK,
} from "../../../mine/utils";
import Zeus from "../../../../assets/icons/Zeus.png";
import Hera from "../../../../assets/icons/Hera.png";
import Poseidon from "../../../../assets/icons/Poseidon.png";
import Athena from "../../../../assets/icons/Athena.png";
import Krishna from "../../../../assets/icons/Krishna.png";
import Varaha from "../../../../assets/icons/Varaha.png";
import Rama from "../../../../assets/icons/Rama.png";
import Odin from "../../../../assets/icons/Odin.png";
import Loki from "../../../../assets/icons/Loki.png";
import Thor from "../../../../assets/icons/Thor.png";
import Balder from "../../../../assets/icons/Balder.png";
import Wukong from "../../../../assets/icons/Sun Wukong.png";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function WalletSection({ user }) {
  // const [tokens, setTokens] = useState(0);
  const [exchangeIcon, setExchangeIcon] = useState();

  const exchanges = [
    { name: "Zeus", Icon: Zeus },
    { name: "Hera", Icon: Hera },
    { name: "Poseidon", Icon: Poseidon },
    { name: "Athena", Icon: Athena },
    { name: "Krishna", Icon: Krishna },
    { name: "Varaha", Icon: Varaha },
    { name: "Rama", Icon: Rama },
    { name: "Odin", Icon: Odin },
    { name: "Loki", Icon: Loki },
    { name: "Thor", Icon: Thor },
    { name: "Balder", Icon: Balder },
    { name: "Sun Wukong", Icon: Wukong },
  ];
  useEffect(() => {
    exchanges.map((exchange) => {
      if (exchange.name === user?.selectedExchange) {
        setExchangeIcon(exchange.Icon);
      }
    });
  }, [user?.selectedExchange]);

  // useEffect(() => {
  //   setTokens(parseFloat(user?.tokensWhenComesOnline).toFixed());
  // }, [user]);

  return (
    <div className="d-flex mt-2">
      <div className="progressBarWrap">
        <div className="d-flex barDetailsWrap justify-content-between align-items-center mb-0">
          <span>
            {user?.level?.name} {">"}
          </span>
          {/* <p className="mb-0"> */}
          <span>{user?.level?.levelNo}/11</span> 
          {/* </p> */}
        </div>
        <Link className="w-100">
          <ProgressBar
            now={(user?.tokensWhenComesOnline / user?.level?.value) * 100}
            className="custom-progress-bar"
          />
        </Link>
      </div>
      <div className="walletSectionWrap d-flex justify-content-between align-items-center">
        <div>
          <Link to="/choose-exchange">
            <img
              src={exchangeIcon ? exchangeIcon : bunnyImg}
              alt="bunnyImg"
              height="28px"
            />
          </Link>
        </div>
        <div className="walletCenterWrap">
          <p>Profit per hour</p>
          <div className="walletCoinDiv">
            <img src={goldCoin} alt="goldicon" />
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-top" style={{fontSize: "11px"}}>
                 {formatNumberWithCommas(user?.minutlyGain?.value)}
                </Tooltip>
              }
            >
              <span className="text-white walletCoinDivSize">
                {formatToK(user?.minutlyGain?.value)}
              </span>
            </OverlayTrigger>
          </div>
        </div>
        <div className="settingIconWrap d-flex align-items-center">
          <Link to="/setting" className="mt-1">
            <FontAwesomeIcon icon={faCog} height="60px" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default WalletSection;
