import React, { useState,useEffect,useContext, useRef } from "react";
import "./redChipper.css";
import { CoinIcon, EnergyIcon } from "../../common-componants/icons/icons";
import { Link } from "react-router-dom";
import BoostImg from "../../assets/launch.png";
import goldCoin from "../../assets/gold-coin.png";
import rabbitImg from "../../assets/rabbitGame-removebg-preview.png";
import tapBunny from "../../assets/bunnie.png"
import UserContext from "../../context/userContext";
import { userInstance } from "../../config/axios";
import { toast } from "react-toastify";
import { formatNumberWithCommas } from "../mine/utils";
import { useLocation } from "react-router-dom";
import socket from "../../config/socket";
import usePreventScroll from "../../utils/usePreventScroll";
import useUpgradeUserLevel from "../../utils/useUpgradeUserLevel";

const morseCodeMap = {
  A: ".-",
  B: "-...",
  C: "-.-.",
  D: "-..",
  E: ".",
  F: "..-.",
  G: "--.",
  H: "....",
  I: "..",
  J: ".---",
  K: "-.-",
  L: ".-..",
  M: "--",
  N: "-.",
  O: "---",
  P: ".--.",
  Q: "--.-",
  R: ".-.",
  S: "...",
  T: "-",
  U: "..-",
  V: "...-",
  W: ".--",
  X: "-..-",
  Y: "-.--",
  Z: "--..",
};

let newPositions = [];
let countervalue = 0;

function RedChipper() {
  const {user, setUser, redScreen, hapticEnabled} = useContext(UserContext);
  const [goldCoinCount, setGoldCoinCount] = useState(parseInt(user?.tokens))
  const [flyNumber, setFlyNumber] = useState(false);
  // const [position, setPosition] = useState({ top: 0, left: 0 });
  const [userInput, setUserInput] = useState(""); 
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0); 
  const [revealedWord, setRevealedWord] = useState(""); 
  const [tapType, setTapType] = useState()
  const [startTime, setStartTime] = useState(null);
  const [targetWord, setTargetWord] = useState("")
  const [morseClicked, setMorseClicked] = useState(false)
  // const [exchangeIcon, setExchangeIcon] = useState()
  const [positions, setPositions] = useState([]);
  const [energy, setEnergy] = useState(user?.remainingPerMinute);

  const restrictedDivRef = useRef(null);
  usePreventScroll(restrictedDivRef)

// Function to check if user's token increasesa certain limit  then we update level
// Sometime user upgraded from 2-4 due to higher reward,that why we need this
  useUpgradeUserLevel(user, setUser)



  //-------for morse code-----------------
  const getTodaysWord = async() => {
    const resp = await userInstance().get("/getTodaysMorse");
    console.log(resp.data.word)
    setTargetWord(resp.data.word)
  }
  useEffect(()=>{
    getTodaysWord()
  },[])
  
  //------On state change from redscreen to green screen-------------//
  useEffect(()=>{
    setPositions([]);
    newPositions = [];
  },[redScreen])

  //-----Socket for when we tap on icon and our api send this socket)
  useEffect(() => {
    socket.on("updateTokenn", (updatedUser) => {
      setUser((prevUser) => ({
        ...prevUser,
        tokens: updatedUser?.tokens,
        remainingPerMinute: updatedUser?.remainingPerMinute,
        tokensWhenComesOnline: updatedUser?.tokensWhenComesOnline,
      }));
    });
  }, []);

  const updateToken = (user) => {
    return new Promise((resolve, reject) => {
      userInstance()
        .post("/updateToken", {
          userId: user?._id,
          levelNo: user?.level?.levelNo,
          remainingPerMinute: user?.remainingPerMinute,
          multi: user?.multitap?.enable,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };


// we are showing equivalent energy not our database energy(remaining per minute), because in the backend it takes time
// to update remaining per minute, so we update state here so we can show smoother animation to user...
  useEffect(() => {
    const interval = setInterval(() => {
      setEnergy((prevEnergy) => {
        if (prevEnergy < user?.level?.oneTimeUpdate) {
          const difference = user?.level?.oneTimeUpdate - prevEnergy;
          if (difference > user?.level?.updationEverySecond) {
            return prevEnergy + user?.level?.updationEverySecond;
          } else {
            return prevEnergy + 1;
          }
        }
        return prevEnergy;
      });
    }, 2000);
    return () => clearInterval(interval);
  }, [user?.level?.oneTimeUpdate, user?.level?.updationEverySecond]);

  const handleTouchStart1 = async (event) => {
    try {
      const integerValue = Math.floor(
        energy / user?.level.levelNo
      );
      if (event.touches.length > integerValue) {
        setPositions([]);
        newPositions = [];
      }
      if (energy > 0 ) {
        const container = document.getElementById("container");
        const rect = container.getBoundingClientRect();

        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const radius = rect.width / 2;
        for (let i = 0; i < event.touches.length; i++) {
          const touch = event.touches[i];
          // Calculate the distance from the touch point to the center of the circle
          const distance = Math.sqrt(
            Math.pow(touch.clientX - centerX, 2) +
              Math.pow(touch.clientY - centerY, 2)
          );

          if (distance <= radius && event.touches.length>1) {
            if (window.Telegram.WebApp && hapticEnabled) {
              const { WebApp } = window.Telegram;
              WebApp.ready();
              const noti = WebApp.HapticFeedback;
              noti.notificationOccurred("success");
            }
            const x = touch.clientX - rect.left;
            const y = touch.clientY - rect.top;

            const flyingNumberWidth = 50;
            const clampedX = Math.min(
              Math.max(x, 0),
              rect.width - flyingNumberWidth
            );
            const clampedY = Math.min(Math.max(y, 0), rect.height);

            if (energy > 0) {
              console.log('here')
              newPositions.push({
                top: clampedY,
                left: clampedX,
                countervalue: ++countervalue,
              });
              const difference = energy - user?.level?.levelNo;
              if (difference > 0) {
                setEnergy((prevEnergy) => {
                  if(user?.multitap?.enable){
                    const newEnergy = prevEnergy - (user?.level?.levelNo+2)
                    return newEnergy < 0 ? 0 : newEnergy;
                  }else{
                    const newEnergy = prevEnergy - user?.level?.levelNo
                    return newEnergy < 0 ? 0 : newEnergy;
                  }
                   
                });
                if(user?.remainingPerMinute >11 && user?.remainingPerMinute > energy){
                updateToken(user)
                .then((response) => {
                  console.log("Token updated successfully");
                })
                .catch((error) => {
                  console.error("Error updating token:", error);
                });
              }
              } else {
                setEnergy(0); 
              }  
              setTapType("●");
              setStartTime(null)
              setGoldCoinCount((prevCoins) => prevCoins + user?.level?.levelNo);
              
              if (!flyNumber) {
                setFlyNumber(true);
              }
      
              const positionUpd = newPositions.slice(-50);
      
              setPositions((prevPosi) => {
                return [...positionUpd];
              });
            } else {
              setEnergy(0);
            }
          }
          else
            if( energy > 0){
              if(revealedWord===targetWord){
                resetGame()
              }
              event.preventDefault();
              setStartTime(Date.now()); 
            }
           else {
            console.log("Touch outside circular area ignored");
          }
        }
        
      }
    } catch (error) {
      console.log(error.message);
      // toast.error(error.message, { toastId: "tap-error" });
    }
  };

  const handleTouchEnd = async (event) => {
      if(startTime!==null){
        const endTime = Date.now();
        const newPositions = [];

        const duration = endTime - startTime;
        if (window.Telegram.WebApp && hapticEnabled) {
          const { WebApp } = window.Telegram;
          WebApp.ready();
          const noti = WebApp.HapticFeedback;
          noti.notificationOccurred("success");
        }
        const container = document.getElementById("container");
        const rect = container.getBoundingClientRect();
        
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const radius = rect.width / 2;
        const touch = event.changedTouches[0]; 
        const distance = Math.sqrt(
          Math.pow(touch.clientX - centerX, 2) +
          Math.pow(touch.clientY - centerY, 2)
        );
        console.log('End');
        
        if (distance <= radius) {
          const x = touch.clientX - rect.left;
          const y = touch.clientY - rect.top;

          const flyingNumberWidth = 50; 
          const clampedX = Math.min(Math.max(x, 0), rect.width - flyingNumberWidth);
          const clampedY = Math.min(Math.max(y, 0), rect.height);

          if (energy > 0) {
            console.log('here')
            newPositions.push({
              top: clampedY,
              left: clampedX,
              countervalue: ++countervalue,
            });
            const difference = energy - user?.level?.levelNo;
            if (difference > 0) {
              setEnergy((prevEnergy) => {
                if(user?.multitap?.enable){
                  const newEnergy = prevEnergy - (user?.level?.levelNo+2)
                  return newEnergy < 0 ? 0 : newEnergy;
                }else{
                  const newEnergy = prevEnergy - user?.level?.levelNo
                  return newEnergy < 0 ? 0 : newEnergy;
                }
                 
              });
              if(user?.remainingPerMinute >11 && user?.remainingPerMinute > energy){
              updateToken(user)
              .then((response) => {
                console.log("Token updated successfully");
              })
              .catch((error) => {
                console.error("Error updating token:", error);
              });
            }
            } else {
              setEnergy(0); 
            }  
            setTapType("●");
            setStartTime(null)
            setGoldCoinCount((prevCoins) => prevCoins + user?.level?.levelNo);
            
            if (!flyNumber) {
              setFlyNumber(true);
            }
    
            // const positionUpd = newPositions.slice(-50);
    
            // setPositions((prevPosi) => {
            //   return [...positionUpd];
            // });
          } else {
            setEnergy(0);
          }
        } else {
          console.log("Touch outside circular area ignored");
        }
        setPositions((prevPositions) => [...prevPositions, ...newPositions]);
        setFlyNumber(true);
        const input = duration < 300 ? "." : "-";
        setUserInput((prev) => prev + input);
        setTapType(input === "." ? "●" : "-");
        
        const updatedInput = userInput + input;
        setUserInput(updatedInput);
        if(user?.remainingPerMinute >11 && user?.remainingPerMinute > energy){
          updateToken(user)
          .then((response) => {
            console.log("Token updated successfully");
          })
          .catch((error) => {
            console.error("Error updating token:", error);
          });
        }

        const currentLetter = targetWord[currentLetterIndex];
        const currentMorseCode = morseCodeMap[currentLetter];
      
        if (updatedInput === currentMorseCode) {
          setRevealedWord((prev) => prev + currentLetter);
          setCurrentLetterIndex((prev) => prev + 1);
          setUserInput(""); 
        } else if (!currentMorseCode?.startsWith(updatedInput)) {
          resetGame();
        }
        setStartTime(null);
      }
    
  };

  useEffect(() => {
    if (!flyNumber) {
      const timer = setTimeout(() => {
        setPositions([]);
      }, 700);
      return () => clearTimeout(timer);
    }
  }, [flyNumber]);

  const resetGame = () => {
    setUserInput("");
    setRevealedWord("");
    setCurrentLetterIndex(0);
  };

  const claimMorse = async () =>{
    if(!morseClicked){
      try {
        setMorseClicked(true)
        const resp = await userInstance().post("/claimMorse",{
          userId: user?._id,
        })
        setUser(resp.data)
        resetGame()
        toast.success("Claimed!",{toastId:"morse"})
      } catch (error) {
        toast.error(error.message,{toastId:"morseerror"})
        console.log("Error while claiming combo", error)
      }
    }
  }

  




  // const handleCircleClick = (event) => {
  //   const rect = event.target.getBoundingClientRect();
  //   const x = event.clientX - rect.left;
  //   const y = event.clientY - rect.top;

  //   setPosition({ top: y, left: x });
  //   setFlyNumber(true);
  //   setGoldCoinCount((prevCount) => prevCount + 1);
  //   setTimeout(() => setFlyNumber(false), 1000);
  // };

  // useEffect(()=>{
  //   setGoldCoinCount(parseInt(user?.tokensWhenComesOnline));
  // }, [user]);
  useEffect(() => {
    socket.on("updateUser", (updatedUser) => {
      setUser(updatedUser);
      setGoldCoinCount(
        parseInt(updatedUser?.tokensWhenComesOnline)
      );
    });
  }, []);
  
  return (
    <div className="chipperMainWrap ">
      <div className="roundedGameWrap pb-3  text-center mt-1" ref={restrictedDivRef}>
        <div className="comboDailyWrap d-flex justify-content-between align-items-center pt-0 pb-1 px-3">
          <div>
            <span className="text">{revealedWord==="" ? "Easter eggs" : revealedWord}</span>
          </div>
          <div className="btnDiv">
            {user.claimedMorse ?
              <button>Claimed</button>
            : revealedWord !== "" && targetWord!=="" && revealedWord===targetWord ?
                <button onClick={()=>claimMorse()}>Claim</button>
              :
                <button>
                  <CoinIcon /> +1,000,000
                </button>
            }
          </div>
        </div>
        <p className="mt-2">
          <img src={goldCoin} alt="" />
          {formatNumberWithCommas(goldCoinCount)}
        </p>
        <div
          className="circle-container redChipperPlate "
          // onClick={handleCircleClick}
          onTouchStart={handleTouchStart1}
          onTouchEnd={handleTouchEnd}
          onClick={(e)=>e.preventDefault()}
          data-aos="zoom-in"
          id="container"
        >
          <img src={tapBunny} alt="Rabbit" />
          {flyNumber &&
            user?.remainingPerMinute > 0 &&
            positions &&
            positions?.map((el, i) => {
              // console.log("index", { el, i });
              return (
                <div
                  key={el.countervalue} // Using index as key since each touch is unique in this context
                  className="flying-number"
                  style={{ top: el?.top, left: el?.left }}
                >
                  {tapType}
                </div>
              );
          })}
        </div>
      </div>
    </div>
  );
}

export default RedChipper;
