export const triggerVibration = (numVibrations) => {
    if (window.Telegram.WebApp) {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      const noti = WebApp.HapticFeedback;
  
      let i = 0;
      const interval = setInterval(() => {
        if (i >= numVibrations) {
          clearInterval(interval);
        } else {
          noti.notificationOccurred("success");
          i++;
        }
      }, 90);
    }
  };
  