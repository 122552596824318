import { useEffect } from "react";

const usePreventScroll = (ref) => {
  const handleTouchMove = (e) => {
    // Prevent scrolling but allow other touch-based interactions
    e.preventDefault();
  };

  useEffect(() => {
    const element = ref.current;

    if (element) {
      // Prevent touchmove to block scroll, but allow touchstart for animations or other actions
      element.addEventListener("touchmove", handleTouchMove, { passive: false });
    }

    return () => {
      if (element) {
        // Cleanup event listener on component unmount or ref change
        element.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, [ref]); // Rerun effect only if ref changes
};

export default usePreventScroll;
