import { useState, useContext } from "react";
import { CrossIcon } from "../../../common-componants/icons/icons";
import { userInstance } from "../../../config/axios";
import UserContext from "../../../context/userContext";
import TabCard from "./tabCard";
import { useNavigate } from "react-router-dom";
import teligram from "../../../assets/teligram.png";


export const Market = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate()
    // const [joinedTg, setJoinedTg] = useState(false)

    // const handleJoinTG = async() => {
    //     const resp = await userInstance().post("/userEarnByTasks", {
    //         userId: user._id,
    //         // amount: 5000
    //     });
    //     console.log(resp)
    //     setUser(resp.data);
    //     // setJoinedTg(true)
    //     hidePopup();
    // }
    const showPopup = () => {
        setIsPopupVisible(true);
      };
    const hidePopup = () => {
        setIsPopupVisible(false);
    };
    
    return(
        <div>
            {user.socials["joinedTelegramChannel"] ?
            <TabCard mineType={"Market"} />
            :
            <div> 
                <div className="marketJoin-btnDiv">
                    <p>Join our telegram to access this section</p>
                    <button onClick={showPopup}>Join</button>
                </div>
                {isPopupVisible && (
                    <div className="deletePopupWrap tabCrdPopup cardRoundedWrap pt-3 pb-5 px-3 pl-0">
                        <div className="crossBtnWrap">
                            <span onClick={hidePopup}>
                            <CrossIcon />
                            </span>
                        </div>
                        <div className="walletWrap mineImgPosition text-center mt-5">
                            <h1 className="">Join our Telegram channel.</h1>
                            <img src={teligram} alt="" />
                        </div>
                        <div className="btnsDivWrap airDropsBtnWrap mt-3">
                            <button onClick={()=>navigate('/earn')}>Check</button>
                        </div>
                    </div>
                )}     
            </div>           
            }
        </div>
    )
}