

export const formatToK = (value) => {
  // Ensure the value is a number and is finite
  if (typeof value !== 'number' || !isFinite(value)) {
      return 'Invalid value';
  }
  if (value >= 1000 && value < 1000000) {
      return (value / 1000).toFixed(2) + 'K';
  } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
  }  
  return value.toFixed();
};

  export const formatInt = (value) => {
    if (value >= 1000 && value <1000000) {
        return (value / 1000) + 'K';
    } else if (value >= 1000000){
        return (value / 1000000) + 'M';
    }
    return value
  };

export const formatNumberWithCommas = (number) => {
  return new Intl.NumberFormat('en-US').format(number);
};

export const getBackgroundColor = (index) => {
    const colors = [
      "#00ebe1",
      "#ff3f18",
      "#779b52",
      "#4060c3",
    ];
    return colors[index % colors.length];
  };

// export const updateUserToken = async(amount, userId) => {
//   const resp = await userInstance().post("/updateUserToken",{
//     userId,
//     amount
//   })
//   return resp.data;
// }

