import React, { useContext, useEffect, useState } from "react";
import "./earn.css";
import coinImage from "../../assets/gold-coin.png";
import {
  CoinIcon,
  CrossIcon,
  NextIcon,
  YoutubeRed,
  YoutubeRedLarge,
} from "../../common-componants/icons/icons";
import goldCoin from "../../assets/gold-coin.png";
import { userInstance } from "../../config/axios";
import UserContext from "../../context/userContext";
import TaskList from "./taskList";
import { toast } from "react-toastify";
import gameImg from "../../assets/3d-calendar.webp";
import { formatToK } from "../mine/utils";
import checkImg from "../../assets/correct.png";
import { useLocation } from "react-router-dom";
import useUpgradeUserLevel from "../../utils/useUpgradeUserLevel";

function Earn() {
  const { user, setUser } = useContext(UserContext);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [rewardToUpdate, setRewardToUpdate] = useState({});
  const [rewards, setRewards] = useState([]);
  // const [youtubeRewards, setYoutubeRewards] = useState([]);
  const [youtubeRewardd, setYoutubeReward] = useState([]);
  // const [totalRewardsClaimed, setTotalRewardsClaimed] = useState(0);
  const [isDailyRewardCollected, setIsDailyRewardCollected] = useState(false);
  // const [watchButtonEnabled, setWatchButtonEnabled] = useState(true);
  // const [rewardButtonEnabled, setRewardButtonEnabled] = useState(false);
  // const [timeLeft, setTimeLeft] = useState("");
  const [currentReward, setCurrentReward] = useState();
  const [dailyRewardEnd, setDailyRewardEnd] = useState(false);

  const [dailyClicked, setDailyClicked] = useState(false);
  const [ytClicked, setYtClicked] = useState(false);
  const location = useLocation();

  useUpgradeUserLevel(user, setUser);

  useEffect(() => {
    if (window.Telegram.WebApp && location.pathname !== "/") {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => {
        window.history.back();
        WebApp.BackButton.hide();
      });
    }
  }, []);

  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  const today = date.toISOString().replace(".000Z", ":000Z");

  const getClaimReward = async () => {
    try {
      const resp = await userInstance().post("/getClaimReward", {
        userId: user?._id,
      });
      setRewards(resp.data.rewards);
      // setYoutubeRewards(resp.data.youtubeReward);
      const rewardss = resp.data.rewards;

      for (let i = 0; i < rewardss.length; i++) {
        const reward = rewardss[i];
        if (
          reward?.lastClaimedDate.slice(0, 10) === today.slice(0, 10) &&
          reward?.claimed === true
        ) {
          setIsDailyRewardCollected(true);
          return;
        }
      }
    } catch (error) {
      toast.error(error.message, { toastId: "getExchange" });
      console.log("get exchange error", error);
    }
  };

  const getYtReward = async () => {
    try {
      const resp = await userInstance().get("/getYtRewards");
      setYoutubeReward(resp.data);
    } catch (error) {
      toast.error(error.message, { toastId: "getYtReward" });
    }
  };

  useEffect(() => {
    getClaimReward();
    getYtReward();
  }, []);

  const claimDailyReward = async (day) => {
    if (dailyClicked === false) {
      try {
        setDailyClicked(true);
        const response = await userInstance().post("/claimDailyReward", {
          userId: user._id,
          day,
        });
        // setTotalRewardsClaimed(response.data.totalRewardsClaimed);
        getClaimReward();
        setUser(response.data);
      } catch (error) {
        toast.error("unable to claim daily reward", {
          toastId: "claimDailyReward",
        });
        console.log("Error claiming daily reward:", error);
      }
    }
  };

  // const claimYoutubeReward = async (link) => {
  //   if(!ytClicked){
  //     try {
  //       setYtClicked(true)
  //       const response = await userInstance().post("/claimYoutubeReward", {
  //         userId: user._id,
  //         link,
  //       });
  //       getClaimReward();
  //       setTotalRewardsClaimed(response.data.totalRewardsClaimed);
  //       setIsPopupVisible(false);
  //       setUpdatedUser(response.data);
  //       setUser(response.data);
  //       setYtClicked(false)
  //       if (window.Telegram && window.Telegram.WebApp) {
  //         if (rewardToUpdate.type === "video") {
  //           window.Telegram.WebApp.openLink(rewardToUpdate.link);
  //         }
  //       } else {
  //         window.location.href = rewardToUpdate.link; // Fallback for browsers (if needed)
  //       }
  //     } catch (error) {
  //       toast.error("unable to claim youtube reward", {
  //         toastId: "claimYoutubeReward",
  //       });
  //       console.log("Error claiming YouTube reward:", error);
  //     }
  //   }
  // };

  // const claimYoutubeReward1 = async (link) => {
  //   console.log(ytClicked)
  //   if(!ytClicked){
  //     try {
  //       setYtClicked(true)
  //       const response = await userInstance().post("/claimYoutubeReward", {
  //         userId: user._id,
  //         link,
  //       });
  //       getClaimReward();
  //       setTotalRewardsClaimed(response.data.totalRewardsClaimed);
  //       setIsPopupVisible(false);
  //       setUpdatedUser(response.data);
  //       setUser(response.data);
  //       setYtClicked(false)
  //       hidePopup();
  //     } catch (error) {
  //       toast.error("Unable to claim youtube reward", {
  //         toastId: "claimYoutubeReward1",
  //       });
  //       console.log("Error claiming YouTube reward:", error);
  //     }
  //   }
  // };

  const showPopup = async (reward) => {
    setIsPopupVisible(true);
    setRewardToUpdate(reward);
    // const now = new Date();
    // const ytRewards = updatedUser.youtubeRewards.find(
    //   (yr) => yr.link === reward.link
    // );

    // if (ytRewards !== undefined) {
    //   // window.alert("not undefined")
    //   if (ytRewards.lastClaimed !== null) {
    //     const claimedTime = new Date(ytRewards.lastClaimed);
    //     const timeDifferenceInMinutes = (now - claimedTime) / (60 * 1000);
    //     // window.alert(timeDifferenceInMinutes)
    //     if (timeDifferenceInMinutes >= 60 && timeDifferenceInMinutes < 120) {
    //       setRewardButtonEnabled(true);
    //       setWatchButtonEnabled(false);
    //       setTimeLeft("");
    //     } else if (timeDifferenceInMinutes >= 60) {
    //       setRewardButtonEnabled(false);
    //       setWatchButtonEnabled(true);
    //       setTimeLeft("");
    //     } else {
    //       setWatchButtonEnabled(false);
    //       setRewardButtonEnabled(false);
    //       const remainingTime = 60 - Math.floor(timeDifferenceInMinutes);
    //       setTimeLeft(remainingTime);
    //     }
    //   }
    // } else {
    //   setWatchButtonEnabled(true);
    //   setRewardButtonEnabled(false);
    // }
  };

  const hidePopup = () => {
    setIsPopupVisible(false);
    // setWatchButtonEnabled(true);
    // setRewardButtonEnabled(false);
  };

  const getTimeAndReward = async () => {
    const resp = await userInstance().get("/getTime");
    const date = new Date(resp?.data);
    const formattedDate = date.toISOString().slice(0, 10);
    rewards.forEach((reward, index) => {
      if (reward.lastClaimedDate.slice(0, 10) === formattedDate?.slice(0, 10)) {
        setCurrentReward(reward);
      } else {
        // Only check for the last reward in the array
        if (index === rewards.length - 1) {
          const lastClaimedDate = new Date(reward.lastClaimedDate.slice(0, 10));
          const formattedDateObj = new Date(formattedDate.slice(0, 10));
          if (formattedDateObj > lastClaimedDate) {
            setDailyRewardEnd(true);
          }
        }
      }
    });
  };

  useEffect(() => {
    getTimeAndReward();
  }, [rewards]);

  // const buttonClick = async () => {
  //   console.log("asdsdscxccx", youtubeRewardd[0].rewardAmount);

  //   try {
  //     if (!ytClicked) {
  //       setYtClicked(true);
  //       const response = await userInstance().post("/claimYoutubeReward", {
  //         userId: user?._id,
  //         reward: youtubeRewardd[0].rewardAmount,
  //         youtubeSubscribe: youtubeRewardd[0].youtubeSubscribe,
  //       });
  //       setUser(response.data);
  //       setYtClicked(false);
  //       toast.success("Success", { toastId: "ytrewards" });

  //       if (window.Telegram && window.Telegram.WebApp) {
  //         if (rewardToUpdate.type === "video") {
  //           window.Telegram.WebApp.openLink(rewardToUpdate.link);
  //         }
  //       } else {
  //         window.location.href = rewardToUpdate.link;
  //       }
  //     }
  //   } catch (error) {
  //     toast.error("Unable to claim YouTube reward", {
  //       toastId: "claimYoutubeReward1",
  //     });
  //   }
  // };

  const buttonClick = async (subscribeField, rewardToUpdate) => {
    const index = subscribeField === 'youtubeSubscribe' ? 0 : Number(subscribeField.slice(-1));
    try {
      if (!ytClicked) {
        setYtClicked(true);
        const response = await userInstance().post("/claimYoutubeReward", {
          userId: user?._id,
          reward: youtubeRewardd[index].rewardAmount,
          subscribeField: subscribeField,
          youtubeSubscribe: youtubeRewardd[index].youtubeSubscribe,
        });
        setUser(response.data);
        setYtClicked(false);
        toast.success("Success", { toastId: "ytrewards" });

        if (window.Telegram && window.Telegram.WebApp) {
          if (rewardToUpdate.type === "video") {
            window.Telegram.WebApp.openLink(rewardToUpdate.link);
          }
        } else {
          window.location.href = rewardToUpdate.link;
        }
      }
    } catch (error) {
      toast.error("Unable to claim YouTube reward", {
        toastId: "claimYoutubeReward1",
      });
    }
  };

  const handleRedirect = (url) => {
    // Redirect if the task is completed
    if (user?.youtubeSubscribe && url) {
      window.location.href = url;
    }
  };

  return (
    <>
      <div className="earnMainWrap px-3">
        <div
          className="glowing-container glowing-image-wrapper text-center"
          data-aos="fade-down"
        >
          <img src={coinImage} alt="Glowing Coin" className="glowing-image" />
        </div>
        <div className="contentMainWrap mt-4">
          <h1>Earn more coins</h1>
        </div>
        <div className="bunnyYoutubereWrap mt-4">
          <h2>Bunnies Youtube</h2>
          {youtubeRewardd.map((youtubeReward, index) => {
            const ytRewards = user.youtubeRewards.find(
              (yr) => yr.link === youtubeReward.link
            );
            // Map index to correct field
            const subscribeField = `youtubeSubscribe${index === 0 ? '' : index}`;

            return (
              <div
                key={youtubeReward.link}
                className="cardSection py-2 px-3 mt-3 d-flex align-items-center position-relative"
                onClick={() =>
                  showPopup({
                    type: "video",
                    claimed: ytRewards?.lastClaimed || youtubeReward.lastClaimed,
                    link: youtubeReward?.link,
                    value: youtubeReward?.rewardAmount,
                    subscribeField: subscribeField,
                  })
                }
              >
                <div>
                  <YoutubeRed />
                </div>
                <div className="contentWrap">
                  <h2>{youtubeReward.title}</h2>
                  <p className="mb-0">
                    <img src={goldCoin} alt="" />
                    &nbsp;+{youtubeReward.rewardAmount} &nbsp;
                  </p>
                </div>
                <div className="nextIcon">
                  {user?.[subscribeField] ? (
                    <p className="mb-0" style={{ fontSize: "22px" }}>
                      <img src={checkImg} alt="" />
                    </p>
                  ) : (
                    <NextIcon />
                  )}
                </div>
              </div>
            );
          })}

          {/* <div
            className="cardSection p-2 mt-2 d-flex align-items-center position-relative"
            onClick={() => showPopup({
              type: "video",
              link: "https://www.youtube.com/watch?v=G_-UYcf1v4M",
              value: 100000
            })}>
            <div>
              <YoutubeRed />
            </div>
            <div className="contentWrap">
              <h2>Bitcoin's Biggest Falls</h2>
              <p className="mb-0">
                <CoinIcon /> &nbsp;+5,000 &nbsp;
              </p>
            </div>
            <div className="nextIcon">
              <NextIcon />
            </div>
          </div> */}
        </div>
        <div className="bunnyYoutubereWrap mt-5">
          <h2>Daily Tasks</h2>
          <div
            className="cardSection py-2 px-3 mt-3 d-flex align-items-center position-relative"
            onClick={() =>
              showPopup({
                type: "daily",
                value: 100000,
              })
            }
          >
            <div className="TaskIcons">
              <img src={gameImg} alt="" />
            </div>
            <div className="contentWrap">
              <h2>Claim your daily reward</h2>
              <p className="mb-0">
                <img src={goldCoin} alt="" />
                &nbsp;+954,000 &nbsp;
              </p>
            </div>
            <div className="nextIcon">
              {isDailyRewardCollected ? (
                <p className="mb-0" style={{ fontSize: "22px" }}>
                  <img src={checkImg} alt="" />
                </p>
              ) : (
                <NextIcon />
              )}
            </div>
          </div>
        </div>
      </div>
      <TaskList />

      {isPopupVisible && rewardToUpdate.type === "video" && (
        <div className="deletePopupWrap cardRoundedWrap pt-3 pb-5 px-4">
          <div className="crossBtnWrap">
            <span onClick={hidePopup}>
              <CrossIcon />
            </span>
          </div>
          <div className="walletWrap earnPopup text-center">
            <YoutubeRedLarge />
            <h1>{rewardToUpdate.title}</h1>
            <p>Subscribe to our YouTube channel and get your reward.</p>
          </div>
          <div className="btnsDivWrap airDropsBtnWrap mt-3">
            <div className="text-center mineNumberWrap">
              <p>
                <img src={goldCoin} alt="" />
                {rewardToUpdate && rewardToUpdate.value}
              </p>
            </div>
            {user?.[rewardToUpdate.subscribeField] ? (
              <button onClick={() => handleRedirect(rewardToUpdate.link)}>Task completed</button>
            ) : (
              <button onClick={() => buttonClick(rewardToUpdate.subscribeField, rewardToUpdate)}>Subscribe</button>
            )}
          </div>
        </div>
      )}


      {isPopupVisible && rewardToUpdate.type === "daily" && (
        <div className="deletePopupWrap cardRoundedWrap pt-3 pb-5 px-3">
          <div className="crossBtnWrap">
            <span onClick={hidePopup}>
              <CrossIcon />
            </span>
          </div>
          <div className="walletWrap earnPopup text-center rewardCalendar">
            <img src={gameImg} alt="" />
            <h1>Daily reward</h1>
            <p>Accure coins for logging into the game daily without skipping</p>
          </div>
          <div className="rewards-container mt-3">
            {rewards.map((reward) => (
              <div key={reward.day}>
                {/* <p>Day {reward.day}: </p> */}
                <div key={reward.day} className="reward-card">
                  {reward?.claimed ? (
                    <button disabled={true} className="reward-button-claimed">
                      <p>Day {reward.day}</p>
                      <img src={goldCoin} alt="" className="bonusImgWid" />
                      <p>{formatToK(reward.amount)}</p>
                    </button>
                  ) : reward.lastClaimedDate.slice(0, 10) ===
                    today.slice(0, 10) ? (
                    <button className="reward-button">
                      <p>Day {reward.day}</p>
                      <img src={goldCoin} alt="" className="bonusImgWid" />
                      <p>{formatToK(reward.amount)}</p>
                    </button>
                  ) : (
                    <div>
                      <button disabled={true} className="reward-button">
                        <p>Day {reward.day}</p>
                        <img src={goldCoin} alt="" className="bonusImgWid" />
                        <p>{formatToK(reward.amount)}</p>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          {dailyRewardEnd ? (
            <div className="BUTTONdIV btnsDivWrap airDropsBtnWrap disabled">
              <button>Daily rewards ended for you</button>
            </div>
          ) : !currentReward.claimed && !user.claimedDailyReward ? (
            <div
              onClick={() => claimDailyReward(currentReward.day)}
              className="BUTTONdIV btnsDivWrap airDropsBtnWrap"
            >
              <button>Claim</button>
            </div>
          ) : (
            <div className="BUTTONdIV btnsDivWrap airDropsBtnWrap disabled">
              <button>Please come back tomorrow</button>
            </div>
          )}

          {/* <div className="BUTTONdIV btnsDivWrap airDropsBtnWrap">
             <button>Claim</button>
          </div>
          {isDailyRewardCollected ? (
            <button className="reward-button">Please come back tomorrow</button>
          ) : null} */}
          {/* <div className="btnsDivWrap airDropsBtnWrap mt-3">

            <div className="text-center mineNumberWrap">
              <p>
                <img src={goldCoin} alt="" />
                {rewardToUpdate && rewardToUpdate?.value}
              </p>
            </div>
              <button onClick={() =>claimYoutubeReward(rewardToUpdate.link)}>Claim</button>      
          </div> */}
        </div>
      )}
    </>
  );
}

export default Earn;
