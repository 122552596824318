import { createContext } from "react";

const UserContext = createContext({
  user: {},
  setUser: () => {},
  walletVisible: true,
  setWalletVisible: () => {},
  specialPurchase: false,
  setSpecialPurchase: () => {},
  redScreen: false,
  setRedScreen: () => {},
  hapticEnabled: true,
  setHapticEnabled: () => {}
});

export default UserContext;
