import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import "./mine.css";
import "./componants/card/card.css";
import goldCoins from "../../assets/10992280.png";
import { QuestionMarkImg } from "../../common-componants/icons/icons";
import WalletSection from "../home/componants/walletSection";
import {
  CrossIcon,
  InfoIcon,
  WalletIcon,
} from "../../common-componants/icons/icons";
import tapBunny from "../../assets/bunnie.png";
import goldCoin from "../../assets/gold-coin.png";
import Tabs from "./componants/tabSection";
import walletBunny from "../../assets/Skype_Picture.png";
import EnergyImg from "../../assets/lighting.png";
import BoostImg from "../../assets/Rocket.png";
import { Link } from "react-router-dom";
import UserContext from "../../context/userContext";
import { userInstance } from "../../config/axios";
import { toast } from "react-toastify";
import { formatNumberWithCommas } from "./utils";
import socket from "../../config/socket";
import useIntersectionObserver from "./useintersectionObserver";
import { useLocation } from "react-router-dom";
import usePreventScroll from "../../utils/usePreventScroll";
import useUpgradeUserLevel from "../../utils/useUpgradeUserLevel";

let newPositions = []
let countervalue = 0;

function Mine() {
  const { user, setUser, setWalletVisible, hapticEnabled } = useContext(UserContext);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [combos, setCombos] = useState([]);
  const [mines, setMines] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [positions, setPositions] = useState([]);
  const [flyNumber, setFlyNumber] = useState(false);
  const [matchingCards, setMatchingCards] = useState();
  const [goldCoinCount, setGoldCoinCount] = useState(parseInt(user?.tokens))
  const [comboClicked, setComboClicked] = useState(false);
  const [tiltStyle, setTiltStyle] = useState({ transform: "none" });
  const circleRef = useRef(null);
  const location = useLocation();
  const [activeTouches, setActiveTouches] = useState(0);
  const [energy, setEnergy] = useState(user?.remainingPerMinute);
  const [serverDate, setServerDate] = useState(null);

  const restrictedDivRef = useRef(null);
  usePreventScroll(restrictedDivRef)

  useUpgradeUserLevel(user, setUser)

  useEffect(() => {
    setEnergy(user?.remainingPerMinute);
  }, [user?._id])

  const resetDefault = () => {
    newPositions = [];
    countervalue = 0;
  }
  useEffect(() => {
    resetDefault()
  }, [])

  useEffect(() => {
    if (window.Telegram.WebApp && location.pathname !== "/") {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => {
        resetDefault()
        setPositions([])
        window.history.back();
        WebApp.BackButton.hide();
      });
    }
  }, []);

  const walletRef = useRef(null);

  // Detect visibility of the wallet section
  const isVisible = useIntersectionObserver(walletRef, { threshold: 0.5 });

  useEffect(() => {
    // Update context with visibility state
    setWalletVisible(isVisible);
  }, [isVisible, setWalletVisible]);



  const handleCircleClick = (event) => {
    const circle = circleRef.current;
    if (!circle) return;

    const rect = circle.getBoundingClientRect();

    const circleCenterX = rect.left + (rect.right - rect.left) / 2.5;
    const circleCenterY = rect.top + (rect.bottom - rect.top) / 2.5;

    const clickX = event.clientX;
    const clickY = event.clientY;

    const diffX = clickX - circleCenterX;
    const diffY = clickY - circleCenterY;

    const tiltX = diffY * 0.13;
    const tiltY = -diffX * 0.13;

    setTiltStyle({ transform: `rotateX(${tiltX}deg) rotateY(${tiltY}deg)` });

    setTimeout(() => {
      setTiltStyle({ transform: "none" });
    }, 150);
  };

  useEffect(() => {
    socket.on("updateTokenn", (updatedUser) => {
      setUser((prevUser) => ({
        ...prevUser,
        tokens: updatedUser?.tokens,
        remainingPerMinute: updatedUser?.remainingPerMinute,
        tokensWhenComesOnline: updatedUser?.tokensWhenComesOnline,
      }));
    });
  }, []);

  const updateToken = (user) => {
    return new Promise((resolve, reject) => {
      userInstance()
        .post("/updateToken", {
          userId: user?._id,
          levelNo: user?.level?.levelNo,
          remainingPerMinute: user?.remainingPerMinute,
          multi: user?.multitap?.enable,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // we are showing equivalent energy not our database energy(remaining per minute), because in the backend it takes time
  // to update remaining per minute, so we update state here so we can show smoother animation to user...
  useEffect(() => {
    if (energy > user?.level.oneTimeUpdate) {
      setEnergy(user?.level.oneTimeUpdate)
    }
    const interval = setInterval(() => {
      setEnergy((prevEnergy) => {
        if (prevEnergy < user?.level?.oneTimeUpdate) {
          const difference = user?.level?.oneTimeUpdate - prevEnergy;
          if (difference > user?.level?.updationEverySecond) {
            return prevEnergy + user?.level?.updationEverySecond;
          } else {
            return prevEnergy + 1;
          }
        }
        return prevEnergy;
      });
    }, 2000);
    return () => clearInterval(interval);
  }, [user?.level?.oneTimeUpdate, user?.level?.updationEverySecond]);


  const handleTouchStart = async (event) => {
    try {
      const integerValue = Math.floor(
        energy / user?.level.levelNo
      );
      if (event.touches.length > integerValue) {
        setPositions([]);
        newPositions = [];
      }
      if (energy > 0) {
        setActiveTouches((prev) => prev + event.touches.length);
        const container = document.getElementById("container");
        const rect = container.getBoundingClientRect();

        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const radius = rect.width / 2;
        for (let i = 0; i < event.touches.length; i++) {
          const touch = event.touches[i];
          // Calculate the distance from the touch point to the center of the circle
          const distance = Math.sqrt(
            Math.pow(touch.clientX - centerX, 2) +
            Math.pow(touch.clientY - centerY, 2)
          );

          if (distance <= radius) {
            if (window.Telegram.WebApp && hapticEnabled) {
              const { WebApp } = window.Telegram;
              WebApp.ready();
              const noti = WebApp.HapticFeedback;
              noti.notificationOccurred("success");
            }
            const x = touch.clientX - rect.left;
            const y = touch.clientY - rect.top;

            const flyingNumberWidth = 50;
            const clampedX = Math.min(
              Math.max(x, 0),
              rect.width - flyingNumberWidth
            );
            const clampedY = Math.min(Math.max(y, 0), rect.height);

            if (energy > 0) {
              newPositions.push({
                top: clampedY,
                left: clampedX,
                countervalue: ++countervalue,
              });
              const difference = energy - user?.level?.levelNo;
              if (difference > 0) {
                setEnergy((prevEnergy) => {
                  if (user?.multitap?.enable) {
                    const newEnergy = prevEnergy - (user?.level?.levelNo + 2)
                    return newEnergy < 0 ? 0 : newEnergy;
                  } else {
                    const newEnergy = prevEnergy - user?.level?.levelNo
                    return newEnergy < 0 ? 0 : newEnergy;
                  }

                });
              } else {
                setEnergy(0);
              }
              setGoldCoinCount((prevCoins) => prevCoins + user?.level?.levelNo);
              if (user?.remainingPerMinute > 11 && user?.remainingPerMinute > energy) {
                updateToken(user)
                  .then((response) => {
                    console.log("Token updated successfully");
                  })
                  .catch((error) => {
                    console.error("Error updating token:", error);
                  });
              }
            } else {
              setEnergy(0);
            }
          } else {
            console.log("Touch outside circular area ignored");
          }
        }
        if (!flyNumber) {
          setFlyNumber(true);
        }

        const positionUpd = newPositions.slice(-50);

        setPositions((prevPosi) => {
          return [...positionUpd];
        });
      }
    } catch (error) {
      console.log(error.message);
      // toast.error(error.message, { toastId: "tap-error" });
    }
  };

  const handleTouchEnd = async (event) => {
    if (positions.length && activeTouches - event.changedTouches.length <= 0) {
      // setTimeout(() => {
      //   // setFlyNumber(false);
      //   // setPositions([]);
      // }, 700);
    }
    setActiveTouches((prev) => Math.max(0, prev - event.changedTouches.length));
  };

  useEffect(() => {
    if (!flyNumber) {
      const timer = setTimeout(() => {
        setPositions([]);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [flyNumber]);


  const showPopup = () => {
    setIsPopupVisible(true);
  };

  const hidePopup = () => {
    setIsPopupVisible(false);
  };

  const getCombo = async () => {
    try {
      const resp = await userInstance().get("/getCombo");
      setCombos(resp.data[0].designation);
    } catch (error) {
      toast.error(error.message, { toastId: "getCombo" });
    }
  };

  useEffect(() => {
    (async () => {
      if (user) {
        // getSpecial();
        setMines(user?.minutlyGain?.subscribed);
      } else {
        setMines([]);
      }
    })();
    getCombo();
  }, [user?.minutlyGain?.value]);

  //---------------To get combo-------------------------//
  const serverTime = async () => {
    const resp = await userInstance().get("/getTime")
    const date = new Date(resp?.data)
    const formattedDate = date.toISOString().split('T')[0]; // Extract "YYYY-MM-DD" from server time
    setServerDate(formattedDate);
  }
  useEffect(() => {
    serverTime();
  }, [mines]);
  const { pattern, imageUrls } = useMemo(() => {
    const getCardPattern = () => {
      const matchingComboNames = combos.filter((combo) =>
        mines?.some(
          (mine) =>
            mine.designation === combo &&
            new Date(mine.updatedAt).toISOString().split("T")[0] === serverDate
        )
      );

      console.log("Matching Combos:", matchingComboNames);
      setMatchingCards(matchingComboNames.length);

      const pattern = [];
      const imageUrls = {};

      if (matchingComboNames.length === 3) {
        setDisabled(false);
        for (let i = 0; i < 3; i++) {
          const designation = matchingComboNames[i];
          pattern.push("Combo");
          imageUrls[`Combo-${i}`] = mines.find(
            (m) => m.designation === designation
          )?.imgUrl;
        }
      } else if (matchingComboNames.length === 2) {
        pattern.push("Combo", "Combo", "Mystery");
        imageUrls["Combo-0"] = mines.find(
          (m) => m.designation === matchingComboNames[0]
        )?.imgUrl;
        imageUrls["Combo-1"] = mines.find(
          (m) => m.designation === matchingComboNames[1]
        )?.imgUrl;
      } else if (matchingComboNames.length === 1) {
        pattern.push("Combo", "Mystery", "Mystery");
        imageUrls["Combo-0"] = mines.find(
          (m) => m.designation === matchingComboNames[0]
        )?.imgUrl;
      } else {
        pattern.push("Mystery", "Mystery", "Mystery");
      }
      return { pattern, imageUrls };
    };

    return getCardPattern();
  }, [combos, mines, serverDate]);
  useEffect(() => {
    const preloadImage = (url) => {
      const img = new Image();
      img.src = url;
    };

    Object.values(imageUrls).forEach(preloadImage);
  }, [imageUrls]);
  const claimCombo = async () => {
    if (!comboClicked) {
      try {
        setComboClicked(true);
        const resp = await userInstance().post("/claimCombo", {
          userId: user?._id,
          // amount: 5000000
        });
        setUser(resp.data);
        toast.success("Claimed!", { toastId: "combo" });
      } catch (error) {
        toast.error(error.message, { toastId: "comboerror" });
        console.log("Error while claiming combo", error);
      }
    }
  };

  // useEffect(() => {
  //   setGoldCoinCount(parseInt(user?.tokens));
  // }, [user?.tokens]);

  useEffect(() => {
    socket.on("updateUser", (updatedUser) => {
      setUser(updatedUser);
      setGoldCoinCount(
        parseInt(updatedUser?.tokensWhenComesOnline)
      );
    });
  }, []);

  return (
    <>
      <div className="mineMainWrap" style={{ overflowX: "hidden" }}>
        <div className="px-3" ref={walletRef}>
          <WalletSection user={user} />
        </div>
        <div className="cardRoundedWrap py-4 px-3 mt-3">
          <div className="text-end timeDiv">
            <span onClick={showPopup}>
              {/* 01:02:38 <InfoIcon /> */}
              <InfoIcon />
            </span>
          </div>
          <div className="comboDailyWrap d-flex justify-content-between align-items-center py-2 px-3">
            <div>
              <span className="text"> combo</span>
            </div>
            {matchingCards === 1 ? (
              <div className="d-flex">
                <span className="filled"></span>
                <span className="circle"></span>
                <span className="circle"></span>
              </div>
            ) : matchingCards === 2 ? (
              <div className="d-flex">
                <span className="filled"></span>
                <span className="filled"></span>
                <span className="circle"></span>
              </div>
            ) : matchingCards === 3 ? (
              <div className="d-flex">
                <span className="filled"></span>
                <span className="filled"></span>
                <span className="filled"></span>
              </div>
            ) : (
              <div className="d-flex">
                <span className="circle"></span>
                <span className="circle"></span>
                <span className="circle"></span>
              </div>
            )}

            <div className="btnDiv">
              {!disabled ? (
                <div>
                  {!user.claimedCombo ? (
                    <button onClick={() => claimCombo()}>
                      <img src={goldCoin} alt="" className="clamidBtn" /> Claim
                    </button>
                  ) : (
                    <button>Already Claimed</button>
                  )}
                </div>
              ) : (
                <button disabled={true}>
                  <img src={goldCoin} alt="" className="clamidBtn" /> +5,000,000
                </button>
              )}
            </div>
          </div>
          <div
            className="d-flex w-100 justify-content-between align-items-center"
            data-aos="fade-right"
          >
            {pattern?.map((item, index) => (
              <div key={index} className="mystery-card mt-4">
                {item === "Combo" ? (
                  <div className="position-relative inner-div text-center">
                    <img
                      loading="lazy"
                      src={imageUrls[`Combo-${index}`]}
                      alt="Combo"
                      className="question-mark rounded"
                    />
                    <img
                      loading="lazy"
                      src={goldCoins}
                      alt="Gold Coins"
                      className="gold-coins"
                    />
                  </div>
                ) : (
                  <div className="position-relative inner-div text-center">
                    <QuestionMarkImg className="question-mark" />
                    <img
                      loading="lazy"
                      src={goldCoins}
                      alt="Gold Coins"
                      className="gold-coins"
                    />
                  </div>
                )}
              </div>
            ))}

          </div>
          <div className="text-center mineNumberWrap mt-3">
            <p>
              <img src={goldCoin} alt="" />
              {user ? formatNumberWithCommas(goldCoinCount) : 0}
            </p>
          </div>
          <div className="pb-4">
            <Tabs />
          </div>
          <div ref={restrictedDivRef}>
            <div
              className={`circle-container`}
              ref={circleRef}
              onClick={handleCircleClick}
              style={tiltStyle}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              id="container"
            // data-aos="zoom-in"
            >
              <img src={tapBunny} alt="Rabbit" />
              {flyNumber &&
                user?.remainingPerMinute > 0 &&
                positions &&
                positions?.map((el, i) => {
                  // console.log("index", { el, i });
                  return (
                    <div
                      key={el.countervalue} // Using index as key since each touch is unique in this context
                      className="flying-number"
                      style={{ top: el?.top, left: el?.left }}
                    >
                      {user?.multitap?.enable
                        ? `+${user?.level?.levelNo + 2}`
                        : `+${user?.level?.levelNo}`}
                    </div>
                  );
                })}
            </div>
            <div className="text-white roundedGameWrap  mt-2 d-flex justify-content-between align-items-center">
              <div>
                <img src={EnergyImg} alt="" className="me-0" />{" "}
                <span className="energyNumber">
                  {`${energy === undefined ? user?.remainingPerMinute : energy
                    // user?.remainingPerMinute >= 0
                    //   ? formatNumberWithCommas(parseInt(user?.remainingPerMinute))
                    //   : 0
                    } 
              / ${formatNumberWithCommas(
                      parseInt(user?.level?.oneTimeUpdate)
                    )}`}</span>
              </div>
              <div className="boostWrap">
                <Link to="/boost" style={{ textDecoration: "none" }}>
                  <img src={BoostImg} alt="" className="me-0" />{" "}
                  <span className="energyNumber">Boost</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPopupVisible && (
        <div className="deletePopupWrap cardRoundedWrap pt-3 pb-5 px-4">
          <div className="crossBtnWrap">
            <span onClick={hidePopup}>
              <CrossIcon />
            </span>
          </div>
          <div className="walletWrap mineImgPosition text-center mt-5 position-relative">
            <span className="circlePopup"></span>
            <div className="imgPosition ">
              <img src={walletBunny} alt="" />
            </div>
            {/* <h1 className="mt-4">Connect your TON wallet</h1> */}
            <p>Find 3 combo cards and you will get a 5M coins</p>
          </div>
          <div className="btnsDivWrap airDropsBtnWrap mt-3">
            <button onClick={() => hidePopup()}>
              <WalletIcon /> &nbsp; Hope i am lucky enough!
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Mine;
